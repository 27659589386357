<div class="px-5">
  <h1>Plantas por Comuna</h1>
  <div class="float-end p-5 ">
    <button class="btn btn-secondary" (click)='edit({});'>
      <svg class="bi" width="20" height="18" fill="currentColor">
        <use xlink:href="assets/img/bootstrap-icons.svg#plus-circle" />
      </svg>
      <span class="align-middle"> Crear </span>
    </button>
  </div>
  <app-admin-table #table [mapHeader]="mapHeader" [queryset]="data$">
  </app-admin-table>
</div>



<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Actualizar Dato</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form autocomplete="off" [formGroup]="form" (ngSubmit)="save()">
    <div class="modal-body">

      <select class="w-100 mt-3 pt-3 pb-1 px-3 custom-select custom-select-lg mb-3 form-control rounded-0"
        formControlName="plant" [style.font-size.px]="22" [style.border]="'1.5px solid lightgray'"
        [class.is-invalid]="form.get('plant')?.touched && form.get('plant')?.invalid">
        <option [ngValue]="" selected disabled>Planta</option>
        <option *ngFor="let p of plants" value="{{p.id}}">
          {{p.code}} {{p.name}}</option>
      </select>

      <select class="w-100 mt-3 pt-3 pb-1 px-3 custom-select custom-select-lg mb-3 form-control rounded-0"
        formControlName="comuna" [style.font-size.px]="22" [style.border]="'1.5px solid lightgray'"
        [class.is-invalid]="form.get('comuna')?.touched && form.get('comuna')?.invalid">
        <option [ngValue]="" selected disabled>Comuna</option>
        <option *ngFor="let p of comunas" value="{{p.id}}">
          {{p.name}}</option>
      </select>

      <input type="number" placeholder="Multa *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="fine"
        [class.is-invalid]="form.get('fine')?.touched && form.get('fine')?.invalid" aria-describedby="nameValidation" />

      <select class="w-100 mt-3 pt-3 pb-1 px-3 custom-select custom-select-lg mb-3 form-control rounded-0"
        formControlName="activa" [style.font-size.px]="22" [style.border]="'1.5px solid lightgray'">
        <option [ngValue]="true">Activa</option>
        <option [ngValue]="false">Inactiva</option>
      </select>

    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-success text-uppercase">Actualizar</button>
    </div>
  </form>
</ng-template>
