import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { ExcelService } from 'src/app/services/excel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';
import { Executive } from 'src/app/models/executive';
import { REGIONES_COMUNA } from 'src/app/shared/utils/regiones';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';


@Component({
  selector: 'app-executive',
  templateUrl: './executive.component.html',
  styleUrls: ['./executive.component.css']
})
export class ExecutiveComponent implements OnInit {

  data$!: string;
  mapHeader = {
    id: { display: "#", data: null},
    plant: { display: 'Planta', data: null},
    name: { display: 'Nombre', data: null},
    email: { display: 'Mail', data: null},
    region: { display: 'Region', data: null},
    comuna: { display: 'Comuna', data: null},
    actions: {
      display: 'Acciones',
      sort: false,
      data: () => {
        return `<button class="btn btn-link btn-edit">
                    <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="assets/img/bootstrap-icons.svg#pencil-square" />
                    </svg>
                </button>
                <button class="btn btn-link btn-delete text-danger">
                  <svg class="bi" width="20" height="20" fill="currentColor">
                      <use xlink:href="assets/img/bootstrap-icons.svg#trash" />
                  </svg>
                </button>
              `;
      },
      edit: (data: any) => { this.edit(data) },
      delete: (data: any) => { this.delete(data.id) }
    },
  };
  regionesComunas = REGIONES_COMUNA;

  @ViewChild('table') table!: AdminTableComponent<Executive>;
  @ViewChild("content", { static: true }) content!: ElementRef;

  form!: FormGroup;
  constructor(
    private readonly fb: FormBuilder,
    private readonly modalService: NgbModal,
    private readonly spinner: NgxSpinnerService,
    private readonly toastService: ToastService,
    private readonly excelService: ExcelService,
    private readonly storeService: StoreService,
public responsiveService: ResponsiveLayoutService) {
  }

  ngOnInit(): void {
    this.data$ = 'executive/';
    this.initForm({});
  }

  initForm(el: any) {
    this.form = this.fb.group({
      id: [el?.id],
      plant: [el?.plant, [Validators.required]],
      name: [el?.name, [Validators.required]],
      email: [el?.email, [Validators.required]],
      region: [el?.region, [Validators.required]],
      comuna: [el?.comuna],
    });
    this.form.get('region')?.valueChanges.subscribe({
      next: () => {
        this.form.get('comuna')?.setValue('');
      }
    })
  }

  get comunaByRegion() {
    const region = this.form.get('region')?.value;
    if (region) {
      let comunas = this.regionesComunas.find(r => r.nombreRegion == region)?.comunas;
      return comunas;
    }
    return [];
  }
  edit(ele: any) {
    this.form.reset(ele);

    this.modalService.open(this.content, { centered: true });
  }

  save() {
    if (this.form.valid) {
      this.spinner.show()
      this.storeService.put_executives(this.form.value).subscribe({
        next: () => {
          this.toastService.show('éxito', 'Se ha actualizado correctamente los datos', { classname: 'bg-success text-light toast-top-right', delay: 3000 });
          this.modalService.dismissAll();
          this.spinner.hide()
          this.table.reload();
        },
        error: () => {
          this.toastService.show('error', 'Por favor revise los datos', { classname: 'bg-danger text-light toast-top-right', delay: 4000 });
          this.spinner.hide();
        }
      });
    }
  }

  delete(id: any) {
    this.spinner.show()
    this.storeService.delete_executives(id).subscribe({
      next: () => {
        this.toastService.show('éxito', 'Se ha eliminado correctamente los datos', { classname: 'bg-success text-light toast-top-right', delay: 3000 });
        this.table.reload();
        this.spinner.hide()
      },
      error: () => {
        this.toastService.show('error', 'Por favor revise los datos', { classname: 'bg-danger text-light toast-top-right', delay: 4000 });
        this.spinner.hide();
      }
    });
  }
}
