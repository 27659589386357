import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveLayoutService {

  public isMobile: boolean = false;
  public isTable: boolean = false;
  public isDesktop: boolean = false;

  private readonly stepSource = new BehaviorSubject<number | null>(null);
  currentStep = this.stepSource.asObservable();

  constructor(public breakpointObserver: BreakpointObserver) {
    this.subscribe();
  }

  subscribe(): void {
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe((state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.isMobile = true;
      }
      if (state.breakpoints[Breakpoints.Small]) {
        this.isMobile = true;
      }
      if (state.breakpoints[Breakpoints.Medium]) {
        this.isMobile = false;
      }
      if (state.breakpoints[Breakpoints.Large]) {
        this.isMobile = false;
      }
      if (state.breakpoints[Breakpoints.XLarge]) {
        this.isMobile = false;
      }
      this.stepSource.next(1);
    });

  }

  public styleResponsive(mobile: string, desktop: string, table: string | null = null) {

    return this.currentStep.pipe(
      map(
        d => {
          if (this.isMobile)
            return mobile;
          if (this.isDesktop)
            return desktop;
          if (this.isTable && table !== null)
            return table;
          return desktop;
        }
      )
    );
  }
}
