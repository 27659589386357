import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-alert',
  templateUrl: './modal-alert.component.html',
  styleUrls: ['./modal-alert.component.css']
})
export class ModalAlertComponent {

  title: string = "";
  description: string = "";
  function: any;
  accion: any;
  constructor(public activeModal: NgbActiveModal) { }

  actionFinish() {
    this.function();
  }
}
