import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RutService {
  // Limpiar RUT: Elimina los caracteres no numéricos (puntos, guiones)
  rutClean(rut: string): string {
    if (!rut) return '';
    return rut.replace(/[^\dKk]/g, '').toUpperCase(); // Elimina todos los caracteres no numéricos y no "K/k"
  }

  rutFormat(rut: string): string {
     // Formatear RUT: Devuelve el RUT en el formato estándar (por ejemplo, 18.421.555-1)
    if (!rut) return '';
    const rutCleaned = this.rutClean(rut);
    const body = rutCleaned.slice(0, -1); // Todos los caracteres excepto el último
    const dv = rutCleaned.slice(-1); // El último carácter es el dígito verificador
    const formattedBody = body.replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agrega puntos como separadores de miles
    return `${formattedBody}-${dv}`; // Formatea como 18.421.555-1
  }

  getRutChile(mode: number, rut: string): string | boolean {
    // Obtener RUT Chile: Devuelve el RUT formateado según el modo
    // Opciones del Modo:
    // 0 -> 184215551
    // 1 -> 18421555
    // 2 -> 18.421.555-1
    // 3 -> 18421555-1
    // 4 -> Solo el dígito verificador
    const rutCleaned = this.rutClean(rut);
    const body = rutCleaned.slice(0, -1); // Cuerpo del RUT sin el dígito verificador
    const dv = rutCleaned.slice(-1); // Dígito verificador

    if (this.isValidRut(rutCleaned)) {
      switch (mode) {
        case 0:
          return body + dv;
        case 1:
          return body;
        case 2:
          return this.rutFormat(rut);
        case 3:
          return body + '-' + dv;
        case 4:
          return dv;
        default:
          return false;
      }
    }
    return false; // RUT inválido
  }

  private isValidRut(rut: string): boolean {
    // Validar RUT Chile: Verifica si el RUT es válido según el dígito verificador
    if (!rut || rut.length < 2) return false;
    const rutBody = rut.slice(0, -1);
    const rutCheckDigit = rut.slice(-1);
    const calculatedCheckDigit = this.calculateCheckDigit(rutBody);
    return rutCheckDigit === calculatedCheckDigit;
  }

  private calculateCheckDigit(rutBody: string): string {
    // Calcular el dígito verificador para un cuerpo de RUT dado (sin dígito verificador)
    let sum = 0;
    let factor = 2;
    for (let i = rutBody.length - 1; i >= 0; i--) {
      sum += parseInt(rutBody.charAt(i)) * factor;
      factor = factor === 7 ? 2 : factor + 1;
    }
    const remainder = sum % 11;
    const checkDigit = 11 - remainder;
    if (checkDigit === 10) return 'K';
    if (checkDigit === 11) return '0';
    return checkDigit.toString();
  }
}
