<div class="px-5">
  <h1>SAP LOG</h1>
  <app-admin-table #table [mapHeader]="mapHeader" [queryset]="data$">
  </app-admin-table>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Desea realizar una inyección manual</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-footer">
    <button (click)="save()" class="btn btn-success text-uppercase">Enviar</button>
    <button (click)="modal.dismiss('Cross click')" class="btn btn-danger text-uppercase">Cancelar</button>
  </div>
</ng-template>
