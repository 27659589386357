import { Injectable } from '@angular/core';
import { ConfigService } from '../shared/utils/http';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CotizacionService {
  http: ConfigService;

  constructor(
    private readonly client: HttpClient,
    private readonly router: Router) {
    this.http = new ConfigService(client);
  }

  changeStateCotizacion(data: any) {
    return this.http.post<any>(`cotizacion/close-dispatch/`, data);
  }
}
