import { Component, inject, TemplateRef } from '@angular/core';
import { TableProviderService } from 'src/app/services/table-provider.service';
import { DecimalPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-admin-nav',
  templateUrl: './admin-nav.component.html',
  styleUrls: ['./admin-nav.component.css'],
  providers: [TableProviderService, DecimalPipe]
})
export class AdminNavComponent{
  _opened: boolean = false;
  isAuthenticate: any;
  version;

  private readonly offcanvasService = inject(NgbOffcanvas);
  private offcanvasRef: NgbOffcanvasRef | null = null;

  constructor(
    private readonly route: ActivatedRoute

  ) {
    this.version = environment.version;
    this.route.firstChild?.data.subscribe(params => {
      this.isAuthenticate = params['isAuthenticate'];
    });
  }

  closeOffcanvas() {
    this.offcanvasRef.close();
    this.offcanvasRef = null;
  }

  openOffcanvas(sidebar: TemplateRef<any>) {
    this.offcanvasRef = this.offcanvasService.open(sidebar, { ariaLabelledBy: 'offcanvas-basic-title', animation: true })
  }

  get existUser(){

    return localStorage.getItem('currentUser') && localStorage.getItem('currentUser')!= 'null';
  }
  closeSession(){
    this.closeOffcanvas();
    localStorage.clear();
  }

}
