<nav class="navbar navbar-expand-lg navbar-primary bg-primary flex-row-reverse">
  <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-right" *ngIf="!userInSession">
    <a class="btn btn-link text-white nav-link text-uppercase" id="dropdownFormLogin" ngbDropdownToggle>Login</a>
    <div ngbDropdownMenu aria-labelledby="dropdownFormLogin" [style.width.vw]="responsiveService.isMobile ? 100 : 35">
      <app-login [isPage]="false"></app-login>
    </div>
  </div>
  <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-right" *ngIf="userInSession">
    <a class="btn btn-link text-white nav-link text-uppercase" id="dropdownFormSesion" ngbDropdownToggle>{{userName}}
      <svg class="bi" width="20" height="18" fill="currentColor">
        <use xlink:href="assets/img/user.svg#plus-circle" />
      </svg>
    </a>
    <div ngbDropdownMenu aria-labelledby="dropdownFormSesion" [style.width.vw]="5">
      <button (click)="openForm()" class="btn form-control text-uppercase">Cambiar clave</button>
      <hr>
      <button (click)="closeSession()" class="btn form-control text-uppercase">Cerrar Sesión</button>
    </div>
  </div>
  <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-right" (openChange)="resetForm();">
    <a class="btn btn-link text-white nav-link text-uppercase" id="dropdownForm1" ngbDropdownToggle>Contacto</a>
    <div ngbDropdownMenu aria-labelledby="dropdownForm1" [style.width.vw]="responsiveService.isMobile ? 100 : 35">
      <form class="px-4 py-3 text-center" autocomplete="off" [formGroup]="form" (ngSubmit)="guardar()">
        <h3>Cbb Store Contacto</h3>
        <div class="form-group mb-3">
          <input type="text" class="form-control" formControlName="name" placeholder="Nombre"
            [class.is-invalid]="form.get('name')?.touched && form.get('name')?.invalid">
        </div>
        <div class="form-group mb-3">
          <input type="email" class="form-control" formControlName="email" placeholder="Email"
            [class.is-invalid]="form.get('email')?.touched && form.get('email')?.invalid">
        </div>
        <div class="form-group mb-3">
          <input type="text" class="form-control" formControlName="phone" placeholder="Teléfono"
            [class.is-invalid]="form.get('phone')?.touched && form.get('phone')?.invalid">
        </div>
        <div class="form-group mb-3">
          <select class="form-control" formControlName="region"
            [class.is-invalid]="form.get('region')?.touched && form.get('region')?.invalid">
            <option value="" selected disabled>Región</option>
            <option value="{{item.nombreRegion}}" *ngFor="let item of regionesComunas">
              {{item.nombreRegion}}</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <select class="form-control" formControlName="comuna"
            [class.is-invalid]="form.get('comuna')?.touched && form.get('comuna')?.invalid">
            <option value="" selected disabled>Comuna</option>
            <option value="{{item}}" *ngFor="let item of comunaByRegion">{{item}}</option>
          </select>
        </div>
        <div class="form-group mb-3">
          <textarea class="form-control" formControlName="question" placeholder="Su Consulta" rows="4"
            [class.is-invalid]="form.get('question')?.touched && form.get('question')?.invalid">
                      ></textarea>
        </div>
        <button type="submit" class="btn btn-primary bg-secondary">Enviar</button>
      </form>
    </div>
  </div>
  <div ngbDropdown class="d-inline-block" display="dynamic" placement="bottom-right">
    <a class="btn btn-link text-white nav-link text-uppercase" href="https://compra.cbbstore.cl">Conoce más</a>
  </div>
</nav>
<div class="container-fluid bg-transparent m-1 pb-1" style="padding-top: 2.5rem !important">
  <div [class]="responsiveService.styleResponsive('d-flex px-1', 'd-flex px-5') | async">
    <a routerLink="/" class="nav-link" [class]="responsiveService.styleResponsive('ps-3', '') | async">
      <img src="assets/img/logo.svg" alt="Cbb Store"
          [width]="responsiveService.styleResponsive('100', '150') | async">
    </a>
    <h5 [class]="responsiveService.styleResponsive('m-auto', 'm-5 my-auto') | async"
        style="color: gray;"
        [style.font-size]="responsiveService.isMobile ? '5vw' : '1.7vw'">
      Compra <strong style="color: black;">hormigón online</strong> en simples pasos.
      Despachamos directo a <strong style="color: black;">tu obra</strong>.
    </h5>
  </div>
</div>
<app-step ></app-step>
<ngx-invisible-recaptcha #captchaElem siteKey="siteKey" badge="'bottomright'" useGlobalDomain="false"
  [(ngModel)]="recaptcha" [ngModelOptions]="{ standalone: true }">
</ngx-invisible-recaptcha>
<router-outlet class="container"></router-outlet>
<app-footer class="footer mt-auto"></app-footer>





<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cambiar Contraseña</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form autocomplete="off" [formGroup]="form_psw" (ngSubmit)="update()">
    <div class="modal-body">
      <input type="text" placeholder="Clave *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="password"
        [class.is-invalid]="form_psw.get('password')?.touched && form_psw.get('password')?.invalid"
        aria-describedby="firstnameValidation" />

      <input type="text" placeholder="Nueva Clave *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="new_password_1"
        [class.is-invalid]="form_psw.get('new_password_1')?.touched && form_psw.get('new_password_1')?.invalid"
        aria-describedby="new_password_1Validation" />
      <input type="text" placeholder="Repetir Clave *" [style.border]="'1.5px solid lightgray'"
        [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0"
        formControlName="new_password_2"
        [class.is-invalid]="form_psw.get('new_password_2')?.touched && form_psw.get('new_password_2')?.invalid"
        aria-describedby="new_password_2Validation" />


    </div>
    <p class="text-danger text-center">{{message}}</p>
    <div class="modal-footer">

      <button type="submit" class="btn btn-success text-uppercase">Cambiar</button>
    </div>
  </form>
</ng-template>
