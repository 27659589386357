import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {
  data$!: string;
  mapHeader = {
    order_buy: { display: 'Orden Compra', data: null },

    name: { display: 'Nombre', data: null },
    email: { display: 'Mail', data: null },
    rut: { display: 'RUT', data: null },
    giro: { display: 'Giro', data: null },
    address_payment: { display: 'Dirección', data: null },
    phone: { display: 'Teléfono', data: null },

    product: { display: 'Producto', data: null },
    product_sap: { display: 'Código SAP', data: null },
    plant: { display: 'Planta', data: null },

    total_price: { display: 'Precio Total', data: null },
    request_amount: { display: 'Cantidad', data: null },
    address_dispatch: { display: 'Dirección Despacho', data: null },
    address_latitud: { display: 'Latitud', data: null },
    address_longitud: { display: 'Longitud', data: null },

    schedule: { display: 'Fecha', data: null },
    hours: { display: 'Rango Horario', data: null },

    state: { display: 'Estado', data: null },
    created_at: { display: 'Solicitado', data: null },
    date_buy: { display: 'Día de Pago', data: null },

    pedido: { display: 'Pedido SAP', data: null },
    factura: { display: 'Factura SAP', data: null },
    authorization_code: { display: 'Código de Autorización', data: null },
    ejecutivo_email: { display: 'Ejecutivo', data: null },
  };

  constructor(
    private readonly storeService: StoreService,
    public responsiveService: ResponsiveLayoutService,
    private readonly excelService: ExcelService) {
  }

  ngOnInit(): void {
    this.data$ = 'dispatch/';
  }
}
