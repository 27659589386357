<div class="background d-flex align-items-center"  [class]="responsiveService.styleResponsive('background-mobile', 'background-image') | async">
    <div class="col-12 col-md-5" style="padding: 7% 5%;">
        <p class="color-primary" [style.font-size]="responsiveService.isMobile? '10vw' : '3.15vw'" [style.line-height]="1">
            De pequeñas <br>
            a grandes obras</p>
        <p class="mt-4" style="color: gray; font-size: 30px;" [style.line-height]="1"
            [style.font-size]="responsiveService.isMobile? '7vw' : '1.8vw'">
            Compra <strong style="color: black;">hormigón online</strong><br> en simples pasos.</p>

        <a class="btn btn-secondary mt-4 text-uppercase" routerLink="/mapa"
            [style.padding]="responsiveService.isMobile? '2.5vw 3vw' : '0.7vw 2.1vw'"
            [style.font-size]="responsiveService.isMobile? '4vw' : '1.5vw'">
            compra aquí
        </a>
    </div>
</div>