/// <reference types="datatables.net" />
import { Component, OnInit, QueryList, ViewChildren, Input, TemplateRef, ViewChild } from '@angular/core';
import { SortableDirective } from 'src/app/directives/sortable.directive';
import { TableProviderService } from 'src/app/services/table-provider.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from 'src/app/services/excel.service';
import { environment } from 'src/environments/environment';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-admin-table',
  templateUrl: './admin-table.component.html',
  styleUrls: ['./admin-table.component.css']
})
export class AdminTableComponent<T> implements OnInit {

  @ViewChildren(SortableDirective) headers!: QueryList<SortableDirective<T>>;

  @Input("mapHeader") mapHeader: any;
  @Input("queryset") queryset: any;
  @Input("exportFile") exportFile: any;
  @Input() extraCols?: TemplateRef<any>;
  @ViewChild(DataTableDirective, { static: false })
  private readonly datatableElement!: DataTableDirective;
  dtOptions: any = {};

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    public service: TableProviderService<T>) {
  }

  ngOnInit(): void {
    let columns = []
    for (let key in this.mapHeader) {
      let value = this.mapHeader[key];
      columns.push({
        visible : value['visible'] ?? true,
        orderable: value['sort'] ?? true,
        data: value['data'] ?? function (d: any) {
          return d[key]
        }
      });
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      columns: columns,
      order: [[0, 'desc']],
      scrollX: true,
      language: {
        lengthMenu: "Mostrar _MENU_ registros por página",
        zeroRecords: "No se encontraron resultados",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "No hay registros disponibles",
        infoFiltered: "(filtrado de _MAX_ registros totales)",
        search: "Buscar:",
        paginate: {
          first: "Primero",
          last: "Último",
          next: "Siguiente",
          previous: "Anterior"
        }
      },
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('.btn-edit', row).off('click');
        $('.btn-edit', row).on('click', () => {
          let func = self.mapHeader['actions']['edit'] ?? null;
          if (func !== null)
            func(data);
        });
        $('.btn-delete', row).off('click');
        $('.btn-delete', row).on('click', () => {
          let func = self.mapHeader['actions']['delete'] ?? null;
          if (func !== null)
            func(data);
        });

        // Manejador general para todos los botones con la clase 'btn-action'
        $('.btn-action', row).off('click');
        $('.btn-action', row).on('click', (event: JQuery.TriggeredEvent) => {
          let action = $(event.currentTarget as HTMLElement).data('action');  // Lee el valor de 'data-action'
          let func = self.mapHeader['actions'][action] ?? null;

          if (func !== null) {
            func(data);  // Llama a la función correspondiente según 'data-action'
          }
        });

        return row;
      },
      ajax: `${environment.apiUrl}${this.queryset}`,
    };
  }

  onSort({ column, direction }: any) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  reload() {
    this.datatableElement?.dtInstance.then((dtInstance) => dtInstance.draw());
  }

  compare(a: any, b: any) {
    return 0;
  }

  exportData() {
    this.excelService.exportToFile(this.exportFile, this.queryset, this.mapHeader)
  }

  getDisplay(item: any): string {
    return item?.display;
  }
}
