import { Component, OnInit } from '@angular/core';
import { StepService } from 'src/app/services/step.service';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {
  steps: SteperCard[] = [];
  currentStep: number = 0;
  footer: boolean = true;
  title_view: string = '';
  stepActual=0;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly stepService: StepService,
    public responsiveService: ResponsiveLayoutService

  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(params => {
      this.footer = params['footer'] ?? true;
    });
    this.stepService.currentStep.subscribe({
      next: (step) => {
        this.steps = [
          {
            title: 'Escoge direccion de despacho',
            url: '/mapa',
            active: false
          },
          {
            title: 'Escoge tu proyecto',
            url: '/producto',
            active: false
          },
          {
            title: ' Escoge tu proyecto',
            url: '/producto',
            active: false
          },
          {
            title: 'Elige fecha y hora del despacho',
            url: '/horario',
            active: false
          },
          {
            title: 'Confirma y paga',
            url: '/pago',
            active: false
          },
          {
            url: '',
            active: false
          },
        ]
        if (step) {

          this.steps[step - 1].active = true;
          this.stepActual= step;
        }
        this.currentStep = step;
      }
    });
  }
  disabledLink(step: number) {
    if (this.currentStep == 6)
      return true;

    return step + 1 > this.currentStep
  }


  stepImage(step: number, active: boolean, title: string| undefined) {
    if (!title) {
      title = ''
    }
    this.title_view = title;
    let url = `/assets/img/icons_footer/paso${step}`
    if (active)
      url += '_activo';
    url += '.svg';
    return url;
  }
get ubicacion(){
  this.title_view = this.steps[this.currentStep - 1]?.title ?? '';
  return this.title_view;
}

}
interface SteperCard {
  title?: string;
  url: string;
  active: boolean;
}

