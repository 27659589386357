import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Comuna } from 'src/app/models/comuna';
import { PlantAdmin } from 'src/app/models/plant';
import { StoreService } from 'src/app/services/store.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';
import { ComunaPlant } from 'src/app/models/comuna-plant';
@Component({
  selector: 'app-comuna-planta',
  templateUrl: './comuna-planta.component.html',
  styleUrls: ['./comuna-planta.component.css']
})
export class ComunaPlantaComponent implements OnInit {
  @ViewChild('table') table!: AdminTableComponent<ComunaPlant>;
  @ViewChild('content', { static: true }) content!: ElementRef;
  form!: FormGroup;
  plants!: PlantAdmin[];
  comunas!: Comuna[];

  data$!: string;
  mapHeader = {
    // id: { display: '#', data: null },
    plant_name: { display: 'Planta', data: null },
    comuna_name: { display: 'Comuna', data: null },
    fine: { display: 'Multa', data: null },
    activa: { display: 'Estado', data: (data: any) => data.activa ? 'Activa' : 'Inactiva' },
    actions: {
      display: 'Acciones',
      sort: false,
      data: (data: any) => {
        return `<button class="btn btn-link btn-edit">
                    <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="assets/img/bootstrap-icons.svg#pencil-square" />
                    </svg>
                </button>`;
      },
      edit: (data: any) => { this.edit(data); }
    },
  };

  constructor(
    private readonly storeService: StoreService,
    private readonly modalService: NgbModal,
    private readonly fb: FormBuilder,
    private readonly spinner: NgxSpinnerService,
    private readonly toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.data$ = 'comuna_planta/';
    this.initForm({});
    this.getSelect();
  }

  initForm(el: any) {
    this.form = this.fb.group({
      id: [],
      comuna: ['', [Validators.required]],
      plant: ['', [Validators.required]],
      fine: ['', [Validators.required]],
      activa: ['', [Validators.required]],
    });
  }

  edit(ele: any) {
    this.form.reset(ele);
    let comuna = ele?.comuna?.id ?? this.comunas[0].id;
    let plants = ele?.plant ?? this.plants[0].id;
    let activa = ele?.activa?.id ?? true;
    this.form.get('id')?.setValue(ele.id);
    this.form.get('comuna')?.setValue(comuna);
    this.form.get('plant')?.setValue(plants);
    this.form.get('activa')?.setValue(activa);
    this.modalService.open(this.content, { centered: true });
  }
  save() {
    if (this.form.valid) {
      this.spinner.show();
      this.storeService.pot_comuna_planta(this.form.value).subscribe({
        next: () => {
          this.toastService.show('éxito', 'Se ha actualizado correctamente los datos', { classname: 'bg-success text-light toast-top-right', delay: 3000 });
          this.modalService.dismissAll();
          this.spinner.hide();
          this.table.reload();
        },
        error: () => {
          this.toastService.show('error', 'Por favor revise los datos', { classname: 'bg-danger text-light toast-top-right', delay: 4000 });
          this.spinner.hide();
        }
      });
    }
  }


  getSelect() {
    this.storeService.get_plants_all().subscribe((e) => {
      this.plants = e;
    });
    this.storeService.get_comunas_all().subscribe((e) => {
      this.comunas = e;
    });
  }

  objectToString(objeto: Object) {
    return JSON.stringify(objeto)
  }
}
