<ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" [autohide]="true"
    [delay]="toast.delay || 5000" (hide)="toastService.remove(toast)">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>
        <h6 class="text-uppercase">
            {{ toast.textOrTpl }}
        </h6>
        <p class="mb-0">
            {{ toast.caption }}
        </p>
    </ng-template>
</ngb-toast>