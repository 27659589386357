<div class="col" [class]="responsiveService.styleResponsive('px-2', 'px-5') | async">
  <div class="bg-container-b container-fluid p-0 mb-4">
    <div class="col-12 pt-3" [class]="responsiveService.styleResponsive('d-block', 'd-flex') | async">
      <div class="col offset-1 px-0 py-2">
        <div class="col-10 color-primary mb-4">
          <h6>Producto</h6>
          <h3>{{ order?.product?.name_comercial }}</h3>
        </div>
        <div class="col-10 color-primary mb-4">
          <h6>Cantidad</h6>
          <h3>{{ order?.product?.amount }} m³
            <img class="ms-1" *ngFor="let item of countTruck(order?.product?.amount ?? 0)" src="assets/img/truck.svg"
              alt="truck" [height]="25" [width]="30">
          </h3>
        </div>
        <div class="col-10 color-secondary mb-4">
          <h6>Monto a pagar</h6>
          <h1>{{ order?.schedule?.price | currency:'$ ':'symbol':'.0':'es' }}</h1>
        </div>
        <div class="col-10 color-primary mb-4">
          <h6>Dirección de entrega</h6>
          <h4>{{ order?.map?.address }}</h4>
        </div>
        <div class="col-10 color-primary mb-3">
          <h6>Fecha de entrega</h6>
          <h4>{{ order?.schedule?.date }}</h4>
          <h6>{{ order?.schedule?.schedule_range }}</h6>
        </div>
      </div>
      <div class="col px-3">
        <form autocomplete="off" [formGroup]="form" (ngSubmit)="guardar()">
          <input type="text" placeholder="Correo Electrónico *" [style.border]="'1.5px solid lightgray'"
            [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="email"
            [readonly]="order?.cotizacion" [class.is-invalid]="form.get('email')?.touched && form.get('email')?.invalid"
            aria-describedby="emailValidation" />
          <div id="emailValidation" class="invalid-feedback">
            Por favor ingrese un correo válido
          </div>

          <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
            class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="name"
            [readonly]="order?.cotizacion" [class.is-invalid]="form.get('name')?.touched && form.get('name')?.invalid"
            aria-describedby="nameValidation" />

          <input type="text" placeholder="RUT *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
            class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="rut"
            [readonly]="order?.cotizacion" (change)="checkRUT()"
            [class.is-invalid]="form.get('rut')?.touched && form.get('rut')?.invalid" aria-describedby="rutValidation" />
          <div id="rutValidation" class="invalid-feedback">
            Por favor ingrese un RUT válido
          </div>

          <input type="text" placeholder="Nombre de Obra *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
            class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="giro"
            [readonly]="order?.cotizacion" [class.is-invalid]="form.get('giro')?.touched && form.get('giro')?.invalid"
            aria-describedby="giroValidation" />

          <input type="text" placeholder="Dirección de Facturación *" [style.border]="'1.5px solid lightgray'"
            [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="helper"
            [readonly]="true" autocorrect="off" autocapitalize="off" spellcheck="off"
            [class.is-invalid]="form.get('helper')?.touched && form.get('helper')?.invalid"
            (keyup.enter)="$event.preventDefault();" aria-describedby="helperValidation"
            [ngStyle]="{'background-color': order?.cotizacion ? '#e9ecef' : '#fff'}"
            (click)="order?.cotizacion ? null : openModal(addressModal)" #selectMap />

          <input type="text" placeholder="Teléfono *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
            class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="phone"
            [readonly]="order?.cotizacion" [class.is-invalid]="form.get('phone')?.touched && form.get('phone')?.invalid"
            aria-describedby="phoneValidation" />

          <div class="d-flex justify-content-center form-check my-3">
            <input type="checkbox" id="accept" formControlName="check" [readonly]="order?.cotizacion"
              class="form-check-input" [class.is-invalid]="form.get('check')?.touched && form.get('check')?.invalid">
            <label class="form-check-label" for="accept"></label>
            <span for="check" (click)="openModal(content)" class="btn-link no-underline ps-3" [style.cursor]="'pointer'">Acepto los
              términos y condiciones</span>
          </div>

          <div class="clearfix w-100 my-auto text-center">
            <button class="w-50 btn btn-cancel  p-2 px-auto text-uppercase text-truncate" type="button"
              [style.border]="'1.5px solid lightgray'" [style.font-size.px]="20" (click)="cotizacion()"
              *ngIf="!order?.cotizacion">{{responsiveService.styleResponsive('cotizar', 'guardar
              cotización') | async}}
            </button>
            <button class="w-50 btn btn-secondary  p-2 px-auto text-uppercase" [style.border]="'1.5px solid lightgray'"
              [style.font-size.px]="20">continuar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<form action="{{ transaction.url }}" method="POST" #transactionFrom>
  <input name="token_ws" hidden value="{{ transaction.token }}">
</form>

<ng-template #content let-c="close" let-d="dismiss" class=" w-50">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">
      <img class="me-4" src="assets/img/logo.svg" alt="Cbb Store" [width]="50">
      Términos y Condiciones
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>
      Las facturas por venta de hormigón son emitidas por Cementos Bío Bío S.A.
    </p>
    <p>
      Cbb Ready Mix debe contar con amplias facilidades para ingresar a la obra, cuando lo requiera, a fin de
      verificar las condiciones de seguridad en los accesos a la obra y descarga del hormigón. Para una operación
      sin accidentes se recomienda consultar la "Guía para evitar accidentes en la descarga de hormigón"
      disponible en www.readymix.cl.
    </p>
    <p>
      El comprador será responsable de entregar todas las facilidades de ingreso a la misma a objeto de verificar
      las condiciones óptimas de recepción, colocación y muestreo del hormigón.
    </p>
    <p>
      Los despachos estarán condicionados a la factibilidad de acceso al lugar de entrega (considerar cartilla con
      dimensiones del camión).
    </p>
    <p>
      Es de responsabilidad y costo del comprador la obtención de autorización de pases de ingreso y permisos de
      descarga en el exterior del punto de entrega.
    </p>
    <p>
      El hormigón será entregado en estado natural por medio de la canoa de deslizamiento del camión mixer. El
      traslado desde el camión al punto de colocación y la colocación en el elemento a construir es por cuenta del
      Comprador.
    </p>
    <p>
      El tiempo de descarga máxima es de 45 minutos desde que el camión llega al punto de entrega.
    </p>
    <p>
      El Comprador deberá descargar la totalidad del hormigón solicitado, en caso contrario, el hormigón sobrante
      deberá ser descargado en el lugar habilitado para ello por el Comprador dentro de la misma obra.
    </p>
    <p>
      Cbb Ready Mix se reserva el derecho de suspender temporal o definitivamente la entrega de hormigón al
      Comprador en caso de impedimentos por fuerza mayor, caso fortuito u otras causas que afecten la calidad de
      los hormigones y la seguridad en las condiciones de entrega.
    </p>
    <p>
      No se aceptan modificación al programa de despacho. Cualquier requerimiento modificaciones y/o anulaciones
      al programa de despacho debe ser comunicado con 48 horas de antelación para su posterior evaluación.
    </p>
    <p>
      Cbb Ready Mix no acepta cobros efectuados unilateralmente por el Comprador, generados por inconvenientes
      propios de los servicios contratados.
    </p>
    <p>
      Cbb Ready Mix no será responsable por fenómenos no deseados en el hormigón, producto de condiciones
      particulares de la obra que no hayan sido informada formalmente por el Comprador, tales como
      Especificaciones Técnicas particulares o generales, solicitudes de la ITO, del Proyectista u otras, y/o
      producto de condiciones especiales de obra, tales como: elementos masivos, temperaturas máximas o mínimas de
      los hormigones, adiciones en obra de algún producto en particular, colocación (altura de caída, inclinación
      de canoas, reducciones de tuberías), condiciones de servicio durante su vida útil, condiciones climáticas
      (temperaturas, evaporación), u otros que se aparten de las condiciones normales, corrientes y de buena
      práctica.
    </p>
    <p>
      Las normas de referencia para el diseño, confección, transporte y evaluación de hormigones son NCh170,
      NCh1017, NCh1171, NCh1934, NCh1998, en sus aspectos normales, corrientes y considerados de buena práctica.
    </p>
  </div>
</ng-template>

<ng-template #alertModal let-modal>
  <div class="modal-body alert-background">
    <div style="margin-left: 391px;margin-top: 210px;">
      <h3 style="color: darkblue;">
        Estimados Clientes:
      </h3>
      <h5>
        Para los despachos en comunas bajo <strong>CUARENTENA</strong>, se debe contar con el permiso
        correspondiente
        que lo califique como <strong>Empresa Esencial</strong>
        o la resolución de algún organismo del estado como mandante <strong>(SERVIU, MOP, DOH o Empresa
          Minera)</strong>, los permisos municipales, no son válidos para esta gestión.
      </h5>
      <h5>
        Este documento es indispensable para que podamos realizar el despacho y será solicitado antes de la
        fecha programada.
      </h5>

      <div style="margin-top: 90px;" class="col-11">
        <button (click)="continuarPago();" class="btn btn-primary pull-right"
          style="float: right;	background: #0a1a72; border-color: #0a1a72;	font-size: larger;">
          Continuar al Pago
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addressModal let-modal>
  <div class="modal-body">
    <div class="py-3 m-auto d-block" [formGroup]="formAddress"
      [class]="responsiveService.styleResponsive('row px-0', 'col px-5') | async">
      <h2>Ingrese la ubicación de Facturación</h2>
      <div class="d-flex flex-nowrap">
        <select class="form-control m-2" formControlName="region"
          [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async">
          <option value="" selected disabled>Región</option>
          <option value="{{item.nombreRegion}}" *ngFor="let item of regionesComunas">
            {{item.nombreRegion}}</option>
        </select>

        <select class="form-control m-2" formControlName="comuna"
          [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async">
          <option value="" selected disabled>Comuna</option>
          <option value="{{item}}" *ngFor="let item of comunaByRegion">{{item}}</option>
        </select>
      </div>

      <div class="d-flex flex-nowrap">
        <input [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async" class="form-control m-2"
          type="text" placeholder="Calle" formControlName="street">
        <input [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async" class="form-control m-2"
          type="text" placeholder="Número" formControlName="number">
      </div>
      <button class="btn btn-secondary float-end px-5 py-3" [style.font-size.px]="18" [disabled]="formAddress.invalid"
        (click)="guardarAddress()">Verificar Dirección</button>
    </div>
  </div>
</ng-template>

<ng-template #paymentModal let-modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">
      <img class="me-4" src="assets/img/logo.svg" alt="Cbb Store" [width]="50">
      Seleccione medio de pago
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body d-flex p-5">
    <div class="col">
      <a (click)="flow()">
        <img src="https://www.flow.cl/img/botones/btn-pagar-celeste.png" alt="flow-button" class="pago-button">
      </a>
    </div>
    <div class="col">

      <a (click)="webpay()">
        <img src="assets/img/SVG_WebpayPlus/_FondoBlanco_SVG/_80PX/1.WebpayPlus_FB_80px.svg" class="pago-button"
          alt="webpay-button">
      </a>
    </div>
  </div>
</ng-template>
