<div class=" jumbotron-fluid bg-transparent m-1 pb-1 row" *ngIf="!responsiveService.isMobile">
  <div class="col-12 ps-5 col-md-4 align-self-end ">
    <h2 class="color-primary"> {{ ubicacion }} </h2>
  </div>
  <div class="col-12 col-md-5 col-xl-6 offset-md-2 d-flex " *ngIf="footer">
    <ng-template ngFor let-item [ngForOf]="steps" let-i="index">
      <div class="col-2">
        <a routerLink="{{item.url}}" class="nav-link" routerLinkActive="active" [class.disabled]="disabledLink(i)">
          <img [src]="stepImage(i+1, item.active, item.title)" alt="paso{{item.url}}" class="icon-footer" >
        </a>
      </div>
    </ng-template>
  </div>

</div>
