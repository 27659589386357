import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { ConfigService } from '../shared/utils/http';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  http: ConfigService;

  constructor(
    client: HttpClient,
    private readonly spinner: NgxSpinnerService,

  ) {
    this.http = new ConfigService(client);
  }

  public importFromFile(bstr: string): XLSX.AOA2SheetOpts {
    /* read workbook */
    const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'string' });

    /* grab first sheet */
    const wsname: string = wb.SheetNames[0];
    const ws: XLSX.WorkSheet = wb.Sheets[wsname];

    /* save data */
    const data = <XLSX.AOA2SheetOpts>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

    return data;
  }

  public exportToFile(fileName: string, url_data: any, parser: Record<string, { display: string }> = {}) {
    this.spinner.show();
    this.http.get<any>(`${url_data}?draw=1&length=-1&start=0&order[0][column]=1&order[0][dir]=1&search[value]=`).subscribe({
      next: (e) => {
        let wb = XLSX.utils.book_new();
        let sheet = XLSX.utils.json_to_sheet(e.data.map((x: any) => {
          let d: Record<string, any> = {};
          Object.keys(x).forEach(e => {
            if (parser?.[e]) {
              d[parser[e].display] = x[e];
            }
            else {
              d[e] = x[e];
            }
          });
          return d;
        }));
        wb.SheetNames = ['Hoja1'];
        wb.Sheets['Hoja1'] = sheet;
        XLSX.writeFile(wb, fileName + '.xlsx');
        this.spinner.hide();

      },
      error: ()=>{
        this.spinner.hide();
      }
    });
  }
}
