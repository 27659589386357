import { Component, OnInit } from '@angular/core';
import { StepService } from 'src/app/services/step.service';
import { Dispatch } from 'src/app/models/dispatch';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { delay, tap } from 'rxjs/operators';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CL';
registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css']
})
export class PedidoComponent implements OnInit {
  dispatch!: Observable<Dispatch>;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly spinner: NgxSpinnerService,
    private readonly storeService: StoreService,
    public responsiveService: ResponsiveLayoutService,
    private readonly stepService: StepService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.spinner.show();
      this.dispatch = this.storeService.get_dispatch(params['id']).pipe(
        delay(100),
        tap({ next: () => this.spinner.hide(), error: () => this.spinner.hide() })
      );
    });
    this.stepService.changeStep(6);
  }

}
