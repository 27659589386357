<div class="px-5">
    <h1>Días de Entrega</h1>
    <app-admin-table #table [mapHeader]="mapHeader" [queryset]="data$" >
    </app-admin-table>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Actualizar Configuración</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="save()">
        <div class="modal-body">
            <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="id" hidden />
            <input type="text" placeholder="Día *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="weekDay"
                aria-describedby="emailValidation" readonly />
            <input type="text" placeholder="Valor *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="shift"
                [class.is-invalid]="form.get('shift')?.touched && form.get('shift')?.invalid"
                aria-describedby="shiftValidation" />
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success text-uppercase">Actualizar</button>
        </div>
    </form>
</ng-template>
