import { Schedule } from './schedule';
import { Product } from './product';
import { User } from './user';

export class Order {
    id?: number;
    user?: User;
    map?: Location;
    schedule?: Schedule;
    product?: Product;
    plant?: number;
    cotizacion?: boolean = false;
    token?: string;
    order_buy?: string;
    fine?: number;
    get isValid() {
        return (
            this.map != null ||
            this.product != null ||
            this.schedule != null ||
            this.plant != null
        )
    }
}

export interface Location {
    lat: number;
    lng: number;
    address: string;
}
