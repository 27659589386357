import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";

export class ConfigService {
    constructor(private readonly http: HttpClient) { }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(() => new Error('Something bad happened; please try again later.'));
    }

    post<T>(service: string, data:any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = `${environment.apiUrl}${service}`;
        return this.http.post<T>(url, data, httpOptions)
    }

    put<T>(service: string, data:any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let url = `${environment.apiUrl}${service}`;
        return this.http.put<T>(url, data, httpOptions)
    }

    post_external<T>(url:any, data:any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'Content-Type: application/x-www-form-urlencoded'
            }),
        };

        return this.http.post<T>(url, data, httpOptions)
    }

    get<T>(service: string) {

        const httpOptions = {
            headers: new HttpHeaders({
            }),
        };

        let url = `${environment.apiUrl}${service}`;
        return this.http.get<T>(url, httpOptions);
    }

    delete(service: string) {

        const httpOptions = {
            headers: new HttpHeaders({
            }),
        };

        let url = `${environment.apiUrl}${service}`;
        return this.http.delete(url, httpOptions);
    }
}
