import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { Plant } from 'src/app/models/plant';
import { StepService } from 'src/app/services/step.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { REGIONES_COMUNA } from 'src/app/shared/utils/regiones';
import { Order } from 'src/app/models/order';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {
  @ViewChild("content", { static: true }) modalContent!: TemplateRef<any>;
  @ViewChild("selectMap", { static: true }) selectMap!: ElementRef;
  @ViewChild("contentUser", { static: true }) contentUser!: ElementRef;
  lat: number = -33.452000;
  lng: number = -70.643007;
  center: google.maps.LatLngLiteral = { lat: -33.452000, lng: -70.643007 };
  zoom = 15;
  geocoder = new google.maps.Geocoder();
  form: FormGroup;
  plants!: Observable<Plant[]>;
  plants_comunas!: Observable<any[]>;
  dispatch!: Observable<Order>;
  formUser: FormGroup;
  regionesComunas = REGIONES_COMUNA;

  mapOptions = {
    scrollwheel: true,  // Habilita el zoom con la rueda del ratón
    gestureHandling: 'auto'  // Permite la interacción estándar con el mapa
  };

  constructor(
    private readonly commonService: CommonService,
    private readonly spinner: NgxSpinnerService,
    private readonly storeService: StoreService,
    public responsiveService: ResponsiveLayoutService,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly stepService: StepService,
    private readonly modalService: NgbModal,
  ) {
    const formulario = this.commonService.getAddress()|| {};
    this.form = this.fb.group({
      lat: [formulario?.lat ?? '', [Validators.required]],
      lng: [formulario?.lng ?? '', [Validators.required]],
      comuna: [formulario?.comuna ?? '', [Validators.required]],
      region: [formulario?.region ?? '', [Validators.required]],
      street: [formulario?.street ?? '', [Validators.required]],
      number: [formulario?.number ?? '', [Validators.required]],
      address: [formulario?.address ?? '', [Validators.required]],
    });

    this.formUser = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    this.stepService.changeStep(1);
    this.plants = this.storeService.get_plant_actives();
    this.plants_comunas = this.storeService.get_comuna_planta_active();
  }


  get comunaByRegion() {
    const region = this.form?.get('region')?.value;
    if (region) {
      let arreglo = REGIONES_COMUNA.find(r => r.nombreRegion == region)?.comunas;
      return arreglo?.sort((a,b)=> a.localeCompare(b));
    }
    return [];
  }

  getCoords(address: string) {
    this.spinner.show();
    this.geocoder.geocode({ address }, (results, status) => {
      // Comprobamos que los resultados no sean null y que el estado sea OK
      if (status === google.maps.GeocoderStatus.OK && results && results.length > 0) {
        const location = results[0].geometry.location;
        this.lat = location.lat();
        this.lng = location.lng();
        this.form.get('lat')?.setValue(this.lat);
        this.form.get('lng')?.setValue(this.lng);
        this.center = { lat: this.lat, lng: this.lng };
        this.checkAddress(results[0]);
      } else {
        this.form.setErrors({ noValid: true });
      }
    });
    this.spinner.hide();
  }

  checkAddress(place: google.maps.GeocoderResult) {
    let types = place.types.filter(x => x !== "plus_code" && x !== "route");
    if (types.length > 0) {
      let checks = 0;
      place.address_components.forEach(x => {
        if (x.types.includes('administrative_area_level_1')) {
          checks += 1;
        }
        if (x.types.includes('administrative_area_level_3')) {
          checks += 1;
        }
        if (x.types.includes('route')) {
          checks += 1;
          this.form.get('street')?.setValue(x.long_name, { emitEvent: false });
        }
        if (x.types.includes('street_number')) {
          this.form.get('number')?.setValue(x.long_name, { emitEvent: false });
          checks += 1;
        }
      });
      if (checks == 4) {
        this.form.get('address')?.setValue(place.formatted_address);
        return;
      }
    }
    this.form.setErrors({ noValid: true });
  }

  verificar() {
    const formValue = this.form.value;
    this.getCoords(`${formValue.street} ${formValue.number}, ${formValue.comuna} ${formValue.region}`);
    this.form.markAsPristine();
  }

  get enabledSave() {
    return this.form.valid && this.form.pristine;
  }

  async guardar() {
    if (this.form.valid) {
      this.spinner.show();
      const plant = await this.getPlant();
      if (plant) {
        this.commonService.setLocation({
          lat: this.form.get('lat')?.value,
          lng: this.form.get('lng')?.value,
          address: this.form.get('address')?.value,
        },
          plant);
        this.spinner.hide();
        const user = this.needUser();
        if (user) {
          this.modalService.open(this.contentUser, { centered: true });
        } else {
          this.router.navigateByUrl('/producto');
        }

      }
      else {
        this.spinner.hide();
        this.showAlert();
      }
    }
  }

  showAlert() {
    const modalRef = this.modalService.open(ModalAlertComponent, { centered: true });
    modalRef.componentInstance.title = 'Plantas Deshabilitada';
    modalRef.componentInstance.description = 'Para la dirección seleccionada no se encuentra una planta cercana para su despacho.';
  }

  async getPlant() {

    let plant = await this.getPlantDist();
    if (plant) {
      return plant;
    }
    return await this.getComunaPlant()

  }

  async getComunaPlant() {
    let res: number | null = null;
    let comuna = this.form.get('comuna')?.value;
    let plantLocations = await firstValueFrom(this.plants_comunas)
      .then(
        (plants) => {
          return plants?.find(x => x.comuna.name == comuna)
        },
        () => { return {} }
      );
    if (plantLocations) {
      res = plantLocations.plant
      this.commonService.setfineOrdenData(plantLocations.fine ?? 0);
    }
    return res;

  }

  async getPlantDist() {
    let res: number | null = null;
    let plantLocations = await firstValueFrom(this.plants)
      .then(
        (plants: any) => {
          return plants?.map((p: any) => {
            return {
              id: p.id,
              location: new google.maps.LatLng(p.latitud, p.longitud)
            }
          });
        },
        () => { return [] }
      );

    let min: BigInt = 99999999999999999999999n;
    for (let index = 0; index < 5; index++) {
      const p = plantLocations?.slice(20 * index, 20 * (index + 1));
      if (!p?.length)
        break;
      const requestLocation = new google.maps.LatLng(this.form.get('lat')?.value, this.form.get('lng')?.value);
      const dist: any = await new Promise(resolve => this.getDistance(resolve, requestLocation, p));
      dist.forEach((e: any, i: any) => {
        if (e.distance?.value < 40000 && e.distance?.value < min) {
          min = dist;
          res = p[i].id;
        }
      });
    }
    //Multa por Planta/Comuna es: 0
    this.commonService.setfineOrdenData(0);
    return res;
  }

  getDistance(resolve: any, requestLocation: any, plantLocations: any) {
    const service = new google.maps.DistanceMatrixService();

    return service.getDistanceMatrix({
      origins: [requestLocation],
      destinations: plantLocations.map((x: any) => x.location),
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false
    }, (response, status) => {
      if (status == google.maps.DistanceMatrixStatus.OK)
        resolve(response?.rows[0].elements);
      else
        resolve(null);
    });
  }

  //Funcion con logica de negocio para obtener si es necesario o no mostrar modal del usuario
  needUser() {
    return !this.commonService.getUser();
  }

  async saveUser() {
    let usuario = this.formUser.value;
    if (this.commonService.getStorageDataObject('user')) {
      usuario['user'] = this.commonService.getStorageDataObject('user').id;
    }
    this.storeService.create_user_dispatch(usuario).subscribe({
      next: (e) => {
        this.commonService.setUserOrdenData(e, usuario);
        this.commonService.setUser(usuario);
        const address = this.form.value;
        this.commonService.setMap(address, usuario);
        this.modalService.dismissAll();
        this.router.navigateByUrl('/producto');
        this.spinner.hide();
      }
    });
  }
  //Funcion valida que usuario este valido
  get ngUserValid() {
    return this.formUser.valid;
  }

}
