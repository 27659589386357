<div class="px-5">
    <h1>Ejecutivos</h1>
    <div class="float-end p-5 ">
        <button class="btn btn-secondary" (click)='edit({region: "", comuna: ""});'>
            <svg class="bi" width="20" height="18" fill="currentColor">
                <use xlink:href="assets/img/bootstrap-icons.svg#plus-circle" />
            </svg>
            Nuevo Ejecutivo
        </button>
    </div>
    <app-admin-table #table [mapHeader]="mapHeader" [queryset]="data$">
    </app-admin-table>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Actualizar Configuración</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="save()">
        <div class="modal-body">
            <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="id" hidden />

            <input type="text" placeholder="Planta *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="plant"
                [class.is-invalid]="form.get('plant')?.touched && form.get('plant')?.invalid"
                aria-describedby="emailValidation" />

            <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="name"
                [class.is-invalid]="form.get('name')?.touched && form.get('name')?.invalid"
                aria-describedby="emailValidation" />

            <input type="text" placeholder="Mail *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="email"
                [class.is-invalid]="form.get('email')?.touched && form.get('email')?.invalid"
                aria-describedby="nameValidation" />

            <select [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3  form-control" formControlName="region"
                [class.is-invalid]="form.get('region')?.touched && form.get('region')?.invalid">
                <option value="" selected disabled>Región</option>
                <option value="{{item.nombreRegion}}" *ngFor="let item of regionesComunas">
                    {{item.nombreRegion}}</option>
            </select>

            <select [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3  form-control" formControlName="comuna"
                [class.is-invalid]="form.get('comuna')?.touched && form.get('comuna')?.invalid">
                <option value="" selected>Comuna</option>
                <option value="{{item}}" *ngFor="let item of comunaByRegion">{{item}}</option>
            </select>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success text-uppercase">Actualizar</button>
        </div>
    </form>
</ng-template>
