<div class="px-5">
    <h1>Plantas</h1>
    <app-admin-table #table [mapHeader]="mapHeader" [queryset]="data$">
    </app-admin-table>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Actualizar Planta</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="save()">
        <div class="modal-body">
            <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="id" hidden />
            <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="code"
                aria-describedby="emailValidation" readonly />
            <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="name"
                [class.is-invalid]="form.get('name')?.touched && form.get('name')?.invalid"
                aria-describedby="emailValidation" />
            <input type="text" placeholder="Región *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
                class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="region"
                [class.is-invalid]="form.get('region')?.touched && form.get('region')?.invalid"
                aria-describedby="nameValidation" />
            <input type="text" placeholder="Latitud *" [style.border]="'1.5px solid lightgray'"
                [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0"
                formControlName="latitud"
                [class.is-invalid]="form.get('latitud')?.touched && form.get('latitud')?.invalid"
                aria-describedby="nameValidation" />
            <input type="text" placeholder="Longitud *" [style.border]="'1.5px solid lightgray'"
                [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0"
                formControlName="longitud"
                [class.is-invalid]="form.get('longitud')?.touched && form.get('longitud')?.invalid"
                aria-describedby="nameValidation" />
            <input type="text" placeholder="Carga Incompleta *" [style.border]="'1.5px solid lightgray'"
                [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0"
                formControlName="carga_incompleta_value"
                [class.is-invalid]="form.get('carga_incompleta_value')?.touched && form.get('carga_incompleta_value')?.invalid"
                aria-describedby="nameValidation" />
            <input type="text" placeholder="UP *" [style.border]="'1.5px solid lightgray'"
                [style.font-size.px]="22" class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0"
                formControlName="up"
                [class.is-invalid]="form.get('up')?.touched && form.get('up')?.invalid"
                aria-describedby="nameValidation" />

            <select class="w-100 mt-3 pt-3 pb-1 px-3 custom-select custom-select-lg mb-3 form-control rounded-0"
                formControlName="activa" [style.font-size.px]="22" [style.border]="'1.5px solid lightgray'">
                <option [ngValue]="" selected disabled>Activa *</option>
                <option [ngValue]="true">Activa</option>
                <option [ngValue]="false">Inactiva</option>
            </select>

        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-success text-uppercase">Actualizar</button>
        </div>
    </form>
</ng-template>
