import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepService {

  public editDataDetails: number = 0;
  public subject = new Subject<number>();

  private readonly stepSource = new BehaviorSubject(this.editDataDetails);

  currentStep = this.stepSource.asObservable();

  constructor() { }

  changeStep(step: number) {
    this.stepSource.next(step)
  }
}
