export class StoreStep {
  mapStep?: MapStep;
  product?: Producto;
  hour?: Hour;
  constructor() {
    this.mapStep = new MapStep();
    this.product = new Producto();
  }

}


export class MapStep {
  address?: Address
  userDelivery?: UserDelivery
  constructor() {
    this.address = new Address()
  }
}


export class Address {
  lat?: number;
  lng?: number;
  comuna?: string;
  region?: string;
  street?: string;
  number?: string;
  address?: string;
}


export class UserDelivery {
  name?: string;
  email?: string;
  user?: number;
}


export class Producto {
  general?: number;
  waterproof?: number;
  product?: number;
  alto?: number;
  ancho?: number;
  profundidad?: number;
  volumen?: number;
}


export class Hour {
  day?: any;
  schedule?: number;

}
