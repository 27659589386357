import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Input() isPage: boolean = true;

  form!: FormGroup;
  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly authService: AuthenticationService) {
  }
  ancho: number = 200;

  ngOnInit() {
    if (this.isPage) {

      this.authService.check().then((resp) => {
        if (resp) {
          this.router.navigateByUrl('/admin/plants');
        }
      });
    } else {
      this.ancho = 140;
    }

    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }


  async login(): Promise<boolean> {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(c => c.markAsTouched());
        } else {
          control.markAsTouched();
        }
      });
      return false;
    }

    try {
      const loginSuccess = await this.authService.login(
        this.form.get('username')?.value,
        this.form.get('password')?.value,
        this.isPage
      );

      if (loginSuccess) {
        this.router.navigateByUrl('/admin/plants');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error durante el proceso de login:", error);
      return false;
    }
  }

}
