<nav class="navbar navbar-light bg-light" >
    <a class="navbar-brand d-grid ms-3" href="#" >
        <img src="assets/img/logo.svg" [style.width.px]="90" alt="" loading="lazy">
        <p [style.font-size.px]="14" [style.margin-left.px]="45" [style.margin-top.px]="5">V {{version}}</p>
    </a>

    <button class="navbar-toggler me-3" type="button" (click)="openOffcanvas(sidebar)" [ngClass]="{'d-none': !existUser, 'd-block': existUser }" >
        <span class="navbar-toggler-icon" ></span>
    </button>
</nav>
  <!-- A sidebar -->
  <ng-template #sidebar let-offcanvas>
    <div>
      <div class="offcanvas-body">
        <ul class="nav flex-column">
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/plants']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Plantas
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/horario']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Disponibilidad
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/cotizacion']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Cotizaciones
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/sales']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Ventas
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/contacts']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Contactos
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/sap']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  SAP LOG
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/settings']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Configuraciones
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/shifts']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Días de Entrega
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/region']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Regiones
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/comuna']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Comunas
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/executives']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Ejecutivos
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/comuna_plant']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Planta por Comuna
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/admin/user']" routerLinkActive="active" (click)="closeOffcanvas()" >
                  Usuarios
              </a>
          </li>
          <li class="nav-link">
              <a class="nav-item" [routerLink]="['/']" routerLinkActive="active" (click)="closeSession()" >
                  Cerrar Sesión
              </a>
          </li>
      </ul>
      </div>
    </div>
  </ng-template>

  <!-- Page content -->
  <section class="pt-3">
    <router-outlet></router-outlet>
  </section>










