<footer>

  <div class="d-flex bg-primary py-4 justify-content-center mt-1">
    <div class="d-block d-md-flex width-90 text-white pt-5 pb-4">
      <div class="col-12">
        <div class="row  justify-content-md-center">
          <div class="col-12 text-center">

            Avenida Andrés Bello 2457 Piso 18, Edificio Costanera Center,Providencia, Región Metropolitana, Chile
          </div>
        </div>
        <div class="row  justify-content-md-center mt-3">
          <a class="col-12 col-md-4 d-flex justify-content-center no-underline" style="border-right: 1px solid white; color:white;" href="tel:800 720 720">
            <img class="" src="assets/img/phone.svg" alt="location" [height]="20" [width]="20">&nbsp;800 720 720
          </a>
          <a class="col-12  col-md-4 d-flex justify-content-center no-underline" style="border-right: 1px solid white; color:white;"  href="mailto:ventascbbstore@cbb.cl">
            <img class="" src="assets/img/letter.svg" alt="location" [height]="20" [width]="20"> &nbsp;ventascbbstore&#64;cbb.cl
          </a>
          <div class="col-12 col-md-4 d-flex justify-content-center">© {{ year }} Cementos Bio Bio SA </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex bg-secondary py-1 justify-content-center">
    <div class="d-block d-md-flex width-90 text-white">
      <div class="col-12 mb-6 col-md-6 d-flex py-2">
        <a *ngFor="let social of socialMedia" [href]="social.url" target="_blank" class="me-2"
          rel="noopener noreferrer">
          <img class="" src="{{social.src}}" alt="location" [width]="30" [height]="30">
        </a>
      </div>
    </div>
  </div>
</footer>
