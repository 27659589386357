import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/sortable.directive';
import { NgxSpinnerService } from 'ngx-spinner';

interface SearchResult<T> {
  data: T[];
  total: number;
}

interface State<T> {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn<T>;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number): number => {
  if (v1 < v2) {
    return -1;
  } else if (v1 > v2) {
    return 1;
  } else {
    return 0;
  }
};


function sort<T>(data: T[], column: keyof T, direction: string): T[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(a[column] as string | number, b[column] as string | number);
      return direction === 'asc' ? res : -res;
    });
  }
}


function matches<T>(obj: T, term: string) {
  for (let prop in obj) {
    const value = obj[prop as keyof T];

    if (value !== null && value !== undefined && (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean')) {
      let exits = value.toString().toLowerCase().includes(term.toLowerCase());
      if (exits) return true;
    }
  }
  return false;
}

@Injectable({ providedIn: 'root' })
export class TableProviderService<T> {
  private readonly _loading$ = new BehaviorSubject<boolean>(true);
  private readonly _search$ = new Subject<void>();
  private readonly _data$ = new BehaviorSubject<T[]>([]);
  private readonly _original_data$ = new BehaviorSubject<T[]>([]);
  private readonly _total$ = new BehaviorSubject<number>(0);

  private readonly _state: State<T> = {
    page: 1,
    pageSize: 100,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  constructor(
    private readonly spinner: NgxSpinnerService
  ) {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._data$.next(result.data);
      this._total$.next(result.total);
    });

    this._search$.next();
  }

  get data$() { return this._data$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set data$(data: Observable<T[]>) {
    this._data$.next([]);
    this.searchTerm = '';
    data.subscribe(result => {
      this._data$.next(result);
      this._original_data$.next(result);
      this._total$.next(result.length);
    });
  }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn<T>) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  private _set(patch: Partial<State<T>>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult<T>> {
    this.spinner.show();
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    this._data$.next([]);

    // 1. Ordenar los datos
    const data = sort(this._original_data$.value, sortColumn as keyof T, sortDirection);

    // 2. Filtrar
    const filteredData = data.filter(row => matches(row, searchTerm));
    const total = filteredData.length;

    // 3. Paginación
    const paginatedData = filteredData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this.spinner.hide();
    return of({ data: paginatedData, total });
  }
}
