<div class="px-5">
  <h1>Regiones</h1>
  <app-admin-table #table [mapHeader]="mapHeader" [queryset]="data$">
  </app-admin-table>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Actualizar Configuración</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form autocomplete="off" [formGroup]="form" (ngSubmit)="save()">
    <div class="modal-body">
      <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="id" hidden />

      <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="name"
        [class.is-invalid]="form.get('name')?.touched && form.get('name')?.invalid" aria-describedby="emailValidation" />

      <input type="text" placeholder="Codigo *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="code"
        [class.is-invalid]="form.get('code')?.touched && form.get('code')?.invalid" aria-describedby="emailValidation" />
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-success text-uppercase">Actualizar</button>
    </div>
  </form>
</ng-template>
