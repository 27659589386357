import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/front/home/home.component';
import { MapaComponent } from './pages/front/mapa/mapa.component';
import { PedidoComponent } from './pages/front/pedido/pedido.component';
import { HorarioComponent } from './pages/front/horario/horario.component';
import { PagoComponent } from './pages/front/pago/pago.component';
import { ProductoComponent } from './pages/front/producto/producto.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HeaderComponent } from './shared/header/header.component';

import { ModalAlertComponent } from './shared/modal-alert/modal-alert.component';
import { AdminNavComponent } from './shared/admin-nav/admin-nav.component';

import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, ReCaptchaV3Service } from 'ng-recaptcha-2';

import { ScheduleComponent } from './pages/admin/schedule/schedule.component';
import { SortableDirective } from './directives/sortable.directive';
import { AdminTableComponent } from './shared/admin-table/admin-table.component';
import { CotizacionComponent } from './pages/admin/cotizacion/cotizacion.component';
import { SalesComponent } from './pages/admin/sales/sales.component';
import { ContactsComponent } from './pages/admin/contacts/contacts.component';
import { SettingsComponent } from './pages/admin/settings/settings.component';
import { ShiftComponent } from './pages/admin/shift/shift.component';
import { PlantsComponent } from './pages/admin/plants/plants.component';
import { ComunaComponent } from './pages/admin/comuna/comuna.component';
import { RegionComponent } from './pages/admin/region/region.component';
import { SapComponent } from './pages/admin/sap/sap.component';
import { ExecutiveComponent } from './pages/admin/executive/executive.component';
import { LoginComponent } from './pages/admin/login/login.component';
import { ToastComponent } from './shared/toast/toast.component';
import { TableDataPipe } from './pipes/table-data.pipe';
import { DataTablesModule } from 'angular-datatables';
import {InputNumberModule} from 'primeng/inputnumber';
import { ComunaPlantaComponent } from './pages/admin/comuna-planta/comuna-planta.component';
import { UserComponent } from './pages/admin/user/user.component';
import { StepComponent } from './shared/step/step.component';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbToastModule, NgbModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';
import { StoreService } from './services/store.service';
import { ResponsiveLayoutService } from './services/responsive-layout.service';
import { CommonService } from './services/common.service';
import { AuthenticationService } from './services/authentication.service';
import { ToastService } from './services/toast.service';
import { API_KEY } from './shared/utils/recaptcha';
import { CotizacionService } from './services/cotizacion.service';
import { ExcelService } from './services/excel.service';
import { RutService } from './services/rut.service';
import { StepService } from './services/step.service';
import { TableProviderService } from './services/table-provider.service';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MapaComponent,
    PedidoComponent,
    HorarioComponent,
    PagoComponent,
    FooterComponent,
    HeaderComponent,
    ProductoComponent,
    ModalAlertComponent,
    AdminNavComponent,
    ScheduleComponent,
    SortableDirective,
    AdminTableComponent,
    CotizacionComponent,
    SalesComponent,
    ContactsComponent,
    SettingsComponent,
    ShiftComponent,
    PlantsComponent,
    ComunaComponent,
    RegionComponent,
    SapComponent,
    ExecutiveComponent,
    LoginComponent,
    ToastComponent,
    TableDataPipe,
    ComunaPlantaComponent,
    UserComponent,
    StepComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    //HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxSpinnerModule,
    LayoutModule,
    RecaptchaModule,
    DataTablesModule,
    InputNumberModule,
    RouterModule,
    NgbDatepickerModule,
    NgbToastModule,
    GoogleMapsModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()) ,
    StoreService,
    ResponsiveLayoutService,
    NgbModal,
    NgxSpinnerService,
    ReCaptchaV3Service,
    CommonService,
    AuthenticationService,
    ToastService,
    CotizacionService,
    ExcelService,
    RutService,
    StepService,
    TableProviderService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: API_KEY }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
