<div class="col px-5">
  <div class="bg-container-b container-fluid p-0 mb-4">
    <div class="row">
        <div class="col-12 col-md-6" [class]="responsiveService.styleResponsive('row w-100', 'col') | async">
          <google-map [height]="'450px'" [width]="'inherit'" [center]="center" [zoom]="zoom" [options]="mapOptions">
            <map-marker [position]="{ lat: lat, lng: lng }"></map-marker>
          </google-map>
        </div>

      <div class="col-12 col-md-6 py-3 m-auto d-block" [formGroup]="form"
        [class]="responsiveService.styleResponsive('row px-0', 'col px-5') | async">
        <h2>Ingrese la ubicación de despacho</h2>
        <h5>Mueva el pin en el punto exacto de la entrega</h5>
        <div class="d-flex flex-nowrap">
          <select class="form-control m-2" formControlName="region"
            [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async">
            <option value="" selected disabled>Región</option>
            <option value="{{item.nombreRegion}}" *ngFor="let item of regionesComunas">
              {{item.nombreRegion}}</option>
          </select>

          <select class="form-control m-2" formControlName="comuna"
            [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async">
            <option value="" selected disabled>Comuna</option>
            <option value="{{item}}" *ngFor="let item of comunaByRegion">{{item}}</option>
          </select>
        </div>

        <div class="d-flex flex-nowrap">
          <input [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async" class="form-control m-2"
            type="text" placeholder="Calle" formControlName="street">
          <input [style.font-size.px]="responsiveService.styleResponsive('16', '26') | async" class="form-control m-2"
            type="text" placeholder="Número" formControlName="number">
        </div>
        <label for="street" class="text-danger" *ngIf="form.hasError('noValid')"> Dirección no disponible </label>
        <label for="street" class="text-danger" *ngIf="form.hasError('queryLimit')"> Demasiados intentos de obtener la dirección,
          intente más tarde. </label>
        <button *ngIf="!enabledSave" class="btn btn-secondary float-end px-5 py-3" [style.font-size.px]="18"
          (click)="verificar()">Verificar Dirección</button>
        <button *ngIf="enabledSave" class="btn btn-secondary float-end px-5 py-3" [style.font-size.px]="18"
          (click)="guardar()">CONTINUAR</button>

      </div>
    </div>
  </div>
</div>

<ng-template #contentUser let-modal>
  <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Datos de Contacto</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form autocomplete="off" [formGroup]="formUser" (ngSubmit)="saveUser()">
      <div class="modal-body">
          <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
              class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="name"
              [class.is-invalid]="formUser.get('name')?.touched && formUser.get('name')?.invalid"
              aria-describedby="nameValidation" />

          <input type="text" placeholder="Email *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
              class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="email"
              [class.is-invalid]="formUser.get('email')?.touched && formUser.get('email')?.invalid"
              aria-describedby="emailValidation" />

      </div>
      <div class="modal-footer">
          <button type="submit" class="btn btn-success text-uppercase" [disabled]="!ngUserValid">Guardar</button>
      </div>
  </form>
</ng-template>
