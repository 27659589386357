import { Component } from '@angular/core';
import { StepService } from 'src/app/services/step.service';
import { ActivatedRoute } from '@angular/router';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  steps: SteperCard[] = [];
  currentStep: number = 0;
  footer: boolean = true;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly stepService: StepService,
    public responsiveService: ResponsiveLayoutService
  ) {

  }
  socialMedia = [

    {
      src:"assets/img/facebook.png",
      url: 'https://www.facebook.com/people/Grupo-Cbb/100063692735004/',
      name:"FB"
    },

    {
      src:"assets/img/instagram.png",
      url: 'https://www.instagram.com/grupo_cbb/',
      name:"IG"
    },
    {
      src:"assets/img/linkedin.png",
      url: 'https://www.linkedin.com/company/grupo-cbb/',
      name:"LK"
    },
    {
      src:"assets/img/youtube.png",
      url: 'https://www.youtube.com/@GrupoCbb',
      name:"YT"
    },
    {
      src:"assets/img/spotify.png",
      url: 'https://open.spotify.com/user/31gural53uwceh4uk2jjg72lqe7u',
      name:"sp"
    },
  ]



  get year(){
    return new Date().getFullYear();
  }
}
interface SteperCard {
  url: string;
  active: boolean;
}
