<div class="modal-header">
    <h4 class="modal-title text-uppercase">
        <img class="me-4" src="assets/img/logo.svg" alt="Cbb Store" [width]="50">
        {{ title }}
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <p> {{ description }}</p>
</div>
<div class="modal-footer" *ngIf="function">
    <button class="btn btn-secondary p-2 px-5 text-uppercase" [style.font-size.px]="20"
        (click)="actionFinish();">{{ accion ? accion : 'Finalizar' }}</button>
</div>
