import { Component, OnInit } from '@angular/core';
import { StepService } from 'src/app/services/step.service';
import { CommonService } from 'src/app/services/common.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private readonly stepService: StepService,
    private readonly commonService: CommonService,
    public responsiveService: ResponsiveLayoutService,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit() {

    const usuario = this.authenticationService.currentUser;
    const token = this.authenticationService.currentToken;
    this.commonService.reset();
    this.authenticationService.currentUser=usuario;
    this.authenticationService.currentToken=token;
    this.stepService.changeStep(0);
  }
}
