import { Component, OnInit, ChangeDetectionStrategy, ElementRef, ChangeDetectorRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Product, Category } from 'src/app/models/product';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { CommonService } from 'src/app/services/common.service';
import { StepService } from 'src/app/services/step.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';

@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css'],
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1s', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('1s', style({ opacity: 0, transform: 'translateY(-100px) translateX(-100px)' }))
      ])
    ]),
    trigger('enter-left', [
      transition(':enter', [
        animate('0.5s', style({ opacity: 1, transform: 'translateX(-10px)' }))
      ])
    ]),
    trigger('top-left', [
      transition('* => open', [
        animate('1s', style({ transform: 'translateY(-{{offSetY}}px) translateX(-{{offSetX}}px)' }))
      ],
        {
          params: { offSetY: 1, offSetX: 1 }
        }
      )
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductoComponent implements OnInit {
  categories!: Observable<Category[]>;
  products!: Product[];
  private _products!: Product[];
  step = true;

  selected = null;
  volumen = null;
  volumenCount: any[] | null = null;

  height = 0;
  depth = 0;
  weight = 0;

  form!: FormGroup;
  m3 = 2;
  defaulValueWaterproof = '1';
  constructor(
    private readonly el: ElementRef,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly storeService: StoreService,
    private readonly commonService: CommonService,
    private readonly stepService: StepService,
    public responsiveService: ResponsiveLayoutService,
    private readonly modalService: NgbModal,
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.stepService.changeStep(2);
    this.categories = this.storeService.get_products_category();
  }

  initForm() {
    this.form = this.fb.group({
      general: [null, [Validators.required]],
      detail: ['', [Validators.required]],
      waterproof: ['', [Validators.required]],
      // resistance: ['', [Validators.required]],
      product: ['', [Validators.required]],
      volumen: ['', [Validators.required]],
    });

    this.form.get('detail')?.valueChanges.subscribe((newForm) => {
      this.form.get('waterproof')?.setValue(this.defaulValueWaterproof);
      this.form.get('product')?.setValue('');
      this.updateDetail(newForm);
      this.setVolumemForm(this.m3)

    });

    this.form.get('waterproof')?.valueChanges.subscribe((newForm) => {
      this.updateProduct(newForm);
    });

  }

  /**
   * Funcion utilizada cuando se modifica el detail
   * @param newForm
   */
  updateDetail(newForm: any) {
    this.storeService.get_products_by_category(this.general, newForm).subscribe((e) => {
      this._products = e;
      this.updateProduct(parseInt(this.defaulValueWaterproof));
    });
  }
  updateProduct(newForm: number) {
    this.form.get('product')?.setValue('');
    this.products = this._products?.filter(p => p.hdp == newForm);
    if (this.products) {
      let valor = this.objectToString(this.products[0]);
      this.form.get('product')?.patchValue(valor);
      this.cdr.detectChanges();
    }
  }

  objectToString(objeto: Object) {
    return JSON.stringify(objeto)
  }

  showVolumen(event: any) {
    if (event.toState == 'open') {
      this.stepService.changeStep(3);
      this.volumenCount = new Array(29);
      this.step = false;
    }
    else {
      this.volumenCount = null;
      this.step = true;
    }
  }

  guardar() {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(c => c.markAsTouched());
        } else {
          control.markAsTouched();
        }
      });
      return;
    }
    let product = JSON.parse(this.form.get('product')?.value);
    product.amount = this.form.get('volumen')?.value;
    this.commonService.setProduct(product);
    this.router.navigateByUrl('/horario');
  }
  m3Change() {
    let floor = Math.floor(this.m3)
    let value = this.m3
    if (floor != value) {
      value = this.m3 - .5;
      value = floor + (value <= floor ? .5 : 1)
    }
    setTimeout(() => {
      this.m3 = value;
      this.cdr.detectChanges();
    }, 50)
    this.setVolumemForm(value)
    this.countTruck(this.form.get('volumen')?.value);
  }

  setVolumemForm(valor: any) {
    try {
      valor = parseFloat(valor);
    } catch (ex) {
      console.error('Error al convertir valor a float:', ex);
    }
    this.form.get('volumen')?.setValue(valor);
  }

  countTruck(i: number) {
    return Array(Math.ceil(i / 7.5));
  }

  fowardOption(categoryId: any) {
    this.general = categoryId;
    this.stepService.changeStep(3);
  }

  backOption() {
    this.general = null;
    this.detail = null;
    this.stepService.changeStep(2);
  }

  get general() {
    return this.form.get('general')?.value;
  }

  set general(val) {
    this.form.get('general')?.setValue(val);
  }

  get detail() {
    return this.form.get('detail')?.value;
  }

  get waterproof() {
    return this.form.get('waterproof')?.value;
  }

  set detail(val) {
    this.form.get('detail')?.setValue(val);
  }

  get product() {
    if (this.form.get('product')?.value)
      return JSON.parse(this.form.get('product')?.value);
    return null;
  }

  url_img(general_use: number) {
    let url = "/assets/img/images.jpg";
    switch (general_use) {
      case 0:
        url = "/assets/img/product/construccion.jpg";
        break;
      case 1:
        url = "/assets/img/product/pavimentos.jpg";
        break;
      case 2:
        url = "/assets/img/product/piscinas.jpg";
        break;
      case 3:
        url = "/assets/img/product/terrazas.jpg";
        break;

      default:
        break;
    }
    return url;
  }

  showAlert(description: any, title = 'Medidas incorrectas') {
    const modalRef = this.modalService.open(ModalAlertComponent, { centered: true, backdropClass: 'bg-transparent' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.description = description;
  }

  calculateVolume() {
    let vol = Math.max(2, this.weight * this.height * this.depth);

    let floor = Math.floor(vol)
    let value = vol
    if (floor != value) {
      value = vol - .5;
      value = floor + (value <= floor ? .5 : 1)
    }

    this.m3 = value;
    this.setVolumemForm(value.toString())
  }
}
