<div class="col mb-4" [class]="responsiveService.styleResponsive('px-2', 'px-5') | async">
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="guardar()">
        <div class="bg-container-b container-fluid p-0">
            <div class="col-12  pt-3 px-3"
                [class]="responsiveService.styleResponsive('d-block', 'd-flex') | async">
                <div [class]="responsiveService.styleResponsive('col-12', 'col-7') | async">
                    <ngb-datepicker #dp navigation="arrows" outsideDays="hidden" [maxDate]="datepicker.maxDate"
                        [minDate]="datepicker.minDate" class="w-100 bg-white py-5 px-3"
                        (dateSelect)="selectDate($event)"
                        [markDisabled]="isDisabled">
                    </ngb-datepicker>
                </div>
                <div class="text-center" [class]="responsiveService.styleResponsive('col-12 pt-3', 'col-5 p-3') | async">
                    <h4 class="color-primary">{{ selectedDate }}</h4>
                    <div class="col" *ngIf="schedules | async as list">
                        <div *ngIf="list.length; else loading" class="justify-content-center">
                            <div class="row" *ngFor="let item of list">
                                <div class="form-check d-flex flex-row justify-content-center align-items-center">
                                    <input type="radio" id="{{item.id }}" formControlName="schedule"
                                        [class.is-invalid]="form.get('schedule')?.touched && form.get('schedule')?.invalid"
                                        value="{{ item | json }}" class="form-check-input align-self-center">
                                    <label class="form-check-label mb-4" for="{{item.id }}">
                                    </label>
                                    <div class="px-4 py-2">
                                      <div class="text-start mb-1"><strong>Sección : {{item.section}}</strong></div>
                                      <div class="text-start text-secondary">
                                          <strong class="me-3">Horario : </strong> {{item.schedule_range}} hrs
                                      </div>
                                      <p class="text-center text-white bg-secondary mt-1 p-1 mx-3 py-auto ">
                                          {{ item.price | currency:'$ ':'symbol':'.0':'es' }}</p>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #loading>
                            <h3>
                                No hay disponibilidad de despacho
                            </h3>
                            <h5>
                                Por favor, seleccione otra fecha disponible.
                            </h5>
                        </ng-template>

                    </div>
                </div>
            </div>
            <div class="clearfix">
                <button class="btn btn-secondary float-end p-2 px-5" [style.font-size.px]="20">CONTINUAR</button>
            </div>
        </div>
    </form>
</div>
