import { Component, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { ScheduleAdmin } from 'src/app/models/schedule';
import { ExcelService } from 'src/app/services/excel.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})
export class ScheduleComponent implements OnInit {
  @ViewChild('table') table!: AdminTableComponent<ScheduleAdmin>;

  data$!: string;
  mapHeader = {
    id: { display: 'Id', data: null },
    plant: { display: 'Planta', data: null },
    day: { display: 'Día', data: null },
    date: { display: 'Fecha', data: null },
    schedule_range: { display: 'Rango Horario', data: null },
  };
  file: any;
  constructor(
    public responsiveService: ResponsiveLayoutService,
    private readonly spinner: NgxSpinnerService,
    private readonly storeService: StoreService,
    private readonly excelService: ExcelService) {
  }

  ngOnInit(): void {
    this.data$ = `schedules/`;
  }

  onChange(evt: any): void {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    this.spinner.show();
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      const bstr: string = e.target.result;
      // const data = <any[]>this.excelService.importFromFile(bstr);
      const data = <any[]>bstr.split('\r\n');

      const header: string[] = data[0].split(',');
      const importedData = data.slice(1, -1);
      let disponibilidad = importedData.map(arr => {
        let a = arr.split(',');
        const obj: { [key: string]: string } = {};
        for (let i = 0; i < header.length; i++) {
          const k = header[i];
          obj[k] = a[i];
        }
        return obj;
      })
      this.storeService.post_import_schedule(disponibilidad).subscribe({
        next: () => {
          this.spinner.hide()
          this.table.reload();
        },
        error: () => {
          this.spinner.hide()
        }
      });
    };
    reader.readAsText(target.files[0]);
    this.file = [];
  }
}
