import { Component, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { ExcelService } from 'src/app/services/excel.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';
import { PlantAdmin } from 'src/app/models/plant';
import { CotizacionService } from 'src/app/services/cotizacion.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ModalAlertComponent } from 'src/app/shared/modal-alert/modal-alert.component';

@Component({
  selector: 'app-cotizacion',
  templateUrl: './cotizacion.component.html',
  styleUrls: ['./cotizacion.component.css']
})
export class CotizacionComponent implements OnInit {
  @ViewChild('table') table!: AdminTableComponent<PlantAdmin>;

  data$!: string;
  mapHeader = {
    payment_method: { display: 'Método', data: null },
    order_buy: { display: 'Orden Compra', data: null },

    name: { display: 'Nombre', data: null },
    email: { display: 'Mail', data: null },
    rut: { display: 'RUT', data: null },
    giro: { display: 'Giro', data: null },
    address_payment: { display: 'Dirección', data: null },
    phone: { display: 'Teléfono', data: null },

    product: { display: 'Producto', data: null },
    product_sap: { display: 'Código SAP', data: null },
    plant: { display: 'Planta', data: null },

    total_price: { display: 'Precio Total', data: null },
    request_amount: { display: 'Cantidad', data: null },
    address_dispatch: { display: 'Dirección Despacho', data: null },
    address_latitud: { display: 'Latitud', data: null },
    address_longitud: { display: 'Longitud', data: null },

    schedule: { display: 'Fecha', data: null },
    hours: { display: 'Rango Horario', data: null },

    state: { display: 'Estado', data: null },
    created_at: { display: 'Solicitado', data: null },
    date_buy: { display: 'Día de Pago', data: null },

    authorization_code: { display: 'Código de Autorización', data: null },
    payment_type: { display: 'Tipo de Pago', data: null },
    ejecutivo_email: { display: 'Ejecutivo', data: null },

    actions: {
      display: 'Acciones',
      sort: false,
      data: (data: any) => {
        return `<button class="btn btn-link btn-action" title="Cambiar estado a cerrado" data-bs-toggle="tooltip" data-action="changeStatus" title="Cambiar estado" ${data.state == "Pendiente" ? "" : "disabled"}>
                <svg class="bi" width="20" height="20" fill="currentColor">
                  <use xlink:href="assets/img/bootstrap-icons.svg#check2-all" />
                </svg>
              </button>`;
      },
      changeStatus: (data: any) => { this.changeClosedStatus(data); }
      },
    };

  constructor(
    private readonly storeService: StoreService,
    public responsiveService: ResponsiveLayoutService,
    private readonly excelService: ExcelService,
    private readonly spinner: NgxSpinnerService,
    private readonly toastService: ToastService,
    private readonly cotizacionService: CotizacionService,
    private readonly authService: AuthenticationService,
    private readonly modalService: NgbModal,
    private readonly router: Router,) {
  }

  ngOnInit(): void {
    this.data$ = 'cotizacion/';
  }

  changeStatus(ele: any){
    this.spinner.show();
    const user = this.authService.currentUser as any;
    const userId = user.id;
    this.cotizacionService.changeStateCotizacion({order_buy:ele.order_buy, user_id: userId}).subscribe({
      next: () => {
        this.toastService.show('éxito', 'El estado ha sido cambiado a Cerrado.', { classname: 'bg-success text-light toast-top-right', delay: 3000 });
        this.spinner.hide();
        this.table.reload();
      },
      error: () => {
        this.toastService.show('error', 'Ocurrio un error al intentar cambiar el estado a cerrado', { classname: 'bg-danger text-light toast-top-right', delay: 3000 });
        this.spinner.hide();
      }
    });
  }

  changeClosedStatus(ele: any) {
    const modalRef = this.modalService.open(ModalAlertComponent, { centered: true });
    modalRef.componentInstance.title = '¿Desea continuar?';
    modalRef.componentInstance.accion = 'Sí, cambiar';
    modalRef.componentInstance.description = 'Se cambiará el estado de la cotización a cerrado.';
    modalRef.componentInstance.function = () => {
      this.modalService.dismissAll();
      this.changeStatus(ele);
    };
  }
}
