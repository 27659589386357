import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDatepickerI18n, NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScheduleRestrict, Schedule } from 'src/app/models/schedule';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { CommonService } from 'src/app/services/common.service';
import { firstValueFrom, Observable } from 'rxjs';
import { StepService } from 'src/app/services/step.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap,  delay } from 'rxjs/operators';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CL';
registerLocaleData(localeEs, 'es');



const I18N_VALUES = {
  'es': {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    weekdayFulls: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
    months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  }
};
@Injectable()
export class I18n {
  language = 'es' as const;
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private readonly _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getWeekdayFullName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdayFulls[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
  d(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.css'],
  providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class HorarioComponent implements OnInit {

  form!: FormGroup;
  @ViewChild(NgbDatepicker, { static: true }) datepicker!: NgbDatepicker;
  selectedDate!: string;

  schedules!: Observable<Schedule[]>;

  constructor(
    private readonly calendar: NgbCalendar,
    private readonly locale: NgbDatepickerI18n,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly commonService: CommonService,
    private readonly spinner: NgxSpinnerService,
    private readonly storeService: StoreService,
    private readonly stepService: StepService,
    public responsiveService: ResponsiveLayoutService
  ) { }

  async ngOnInit() {
    this.stepService.changeStep(4);
    this.initForm();
    const initDate = this.calendar.getToday();
    this.spinner.show();
    firstValueFrom(this.storeService.get_schedule_restriction(this.calendar.getWeekday(initDate) % 7))
    .then((e: ScheduleRestrict | undefined) => {
      if (e) {  // Comprobar que 'e' no sea undefined
        this.datepicker.minDate = this.calendar.getNext(this.calendar.getToday(), 'd', e.shift);
        this.datepicker.maxDate = this.calendar.getNext(this.calendar.getToday(), 'd', e.days + e.shift);
      }
      this.spinner.hide();
    });
  }

  initForm() {
    this.form = this.fb.group({
      schedule: ['', [Validators.required]],
    });
  }

  isDisabled = (date: NgbDateStruct, current?: { month: number }) => {
    if (current) {
      const ngbDate = new NgbDate(date.year, date.month, date.day);
      return this.calendar.getWeekday(ngbDate) === 7;
    }
    return false;
  };

  selectDate(_date: NgbDate) {
    this.selectedDate = `${I18N_VALUES.es.weekdayFulls[this.calendar.getWeekday(_date) - 1]}, ${_date.day} de ${this.locale.getMonthFullName(_date.month)} de  ${_date.year}`;
    this.spinner.show();
    this.schedules = this.storeService.post_schedule_options(
      this.commonService.currentOrder.plant as number,
      this.commonService.currentOrder.product?.id as number,
      this.commonService.currentOrder.product?.amount as number,
      `${_date.year}-${_date.month}-${_date.day}`,
      this.commonService.currentOrder.fine as number
    ).pipe(
      delay(100),
      tap(() => this.spinner.hide())
    );
  }

  guardar() {

    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(c => c.markAsTouched());
        } else {
          control.markAsTouched();
        }
      });
      return;
    }
    let schedule = JSON.parse(this.form.get('schedule')?.value) as Schedule;
    schedule.date = this.selectedDate;
    this.commonService.setSchedule(schedule);
    this.router.navigateByUrl('/pago');
  }
}
