import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { REGIONES_COMUNA } from '../utils/regiones';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { API_KEY } from '../utils/recaptcha';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastService } from './../../services/toast.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('content', { static: true }) content?: ElementRef;

  logo_url = '/assets/img/logo.png';
  form: FormGroup;
  form_psw: FormGroup;
  regionesComunas = REGIONES_COMUNA;
  siteKey = API_KEY;
  recaptcha = '';
  message = '';

  userName: | null = null;
  constructor(
    private readonly fb: FormBuilder,
    private readonly modalService: NgbModal,
    private readonly spinner: NgxSpinnerService,
    private readonly storeService: StoreService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service,
    public responsiveService: ResponsiveLayoutService,
    private readonly commonService: CommonService,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    private readonly toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$")]],
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      region: ['', [Validators.required]],
      comuna: ['', [Validators.required]],
      question: ['', [Validators.required]],
      token: ['', [Validators.required]],
    });
    this.form_psw = this.fb.group({
      id: [''],
      password: ['', [Validators.required]],
      new_password_1: ['', [Validators.required,]],
      new_password_2: ['', [Validators.required]],
    });
    this.form.get('region')?.valueChanges.subscribe({
      next: () => {
        if (this.form.get('region')?.value) {
          this.form?.get('comuna')?.setValue('');
        }
      }
    })

  }

  /**
   * revisa si los formularios estan correctos
   * @returns
   */
  errorEqualNewPassword() {
    if (!this.form_psw) {
      return null;
    }
    if (this.form_psw.get('new_password_1')?.invalid || this.form_psw.get('new_password_2')?.invalid) {
      return null;
    }
    const new_password_1 = this.form?.get('new_password_1')?.value;
    const new_password_2 = this.form?.get('new_password_2')?.value;
    if (new_password_1 === new_password_2) {
      return { sameName: true };
    }

    return null;
  }

  get comunaByRegion() {
    const region = this.form?.get('region')?.value;
    if (region) {
      let comunas = REGIONES_COMUNA.find(r => r.nombreRegion == region)?.comunas;
      return comunas;
    }
    return [];
  }

  guardar() {
    this.reCaptchaV3Service.execute('contact').subscribe({
      next: (token: string) => {
        this.form?.get('token')?.setValue(token);

        if (this.form.invalid) {
          Object.values(this.form.controls).forEach(control => {
            if (control instanceof FormGroup) {
              Object.values(control.controls).forEach(c => c.markAsTouched());
            } else {
              control.markAsTouched();
            }
          });
          return;
        }
        this.spinner.show();
        this.storeService.post_help_contact(this.form.value).subscribe({
          next: (response) => {
            this.spinner.hide();
            this.resetForm();
            this.showAlert();

          },
          error: (response) => {
            this.spinner.hide();
          }
        });
      },
      error: (error) => {
        this.spinner.hide();
      }
    });
  }

  showAlert() {
    const modalRef = this.modalService.open(ModalAlertComponent, { centered: true });
    modalRef.componentInstance.title = 'Contacto Recibido';
    modalRef.componentInstance.description = 'Gracias por el mensaje, lo contactaremos a la brevedad.';
  }

  resetForm() {
    this.form?.reset({
      region: '',
      comuna: ''
    });
  }

  get userInSession() {
    const usr = this.commonService.getStorageDataObject('user');
    if (usr && Object.keys(usr).length > 0) {
      this.userName = usr.full_name;
      return true;
    }
    return false;
  }

  closeSession() {
    this.commonService.reset();
    return this.router.navigateByUrl('/');
  }

  openForm() {
    this.modalService.open(this.content, { centered: true });
  }

  compareNames(control: any) {
    if (!this.form) {
      return null;
    }
    const lastName = control.value;
    const firstName = this.form.get('first_name')?.value;
    if (lastName === firstName) {
      return { sameName: true };
    }

    return null;
  }

  update(): void {
    if (this.form_psw?.invalid) {
      Object.values(this.form_psw.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(c => c.markAsTouched());
        } else {
          control.markAsTouched();
        }
      });
      return;
    }
    this.message = "";

    this.spinner.show();
    const usr = this.commonService.getStorageDataObject('user');
    this.authenticationService.update_psw(usr.id, this.form_psw?.value).subscribe({
      next: (response) => {
        this.spinner.hide();
        this.resetForm();
        this.changePsw();
        this.toastService.show('', 'Contraseña modificada ', { classname: 'bg-success text-light this.toastService', delay: 4000 });
        this.closeSession();
        this.modalService.dismissAll();

      },
      error: (response) => {
        this.spinner.hide();
        if (response && response.status == 403) {
          this.message = response?.error?.message ?? "";
        }
      }
    });

  }

  changePsw() {
    const modalRef = this.modalService.open(ModalAlertComponent, { centered: true });
    modalRef.componentInstance.title = '';
    modalRef.componentInstance.description = 'Contraseña modificada.';
  }

}
