<div class="px-5">
  <h1>Usuarios</h1>
  <div class="float-end p-5 ">
    <button class="btn btn-secondary" (click)='edit({});'>
      <svg class="bi" width="20" height="18" fill="currentColor">
        <use xlink:href="assets/img/bootstrap-icons.svg#plus-circle" />
      </svg>
      <span class="align-middle"> Crear </span>
    </button>
  </div>
  <app-admin-table #table [mapHeader]="mapHeader" [queryset]="data$">
  </app-admin-table>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Actualizar Configuración</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form autocomplete="off" [formGroup]="form" (ngSubmit)="save()">
    <div class="modal-body">
      <input type="text" placeholder="Nombre *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="first_name"
        [class.is-invalid]="form.get('first_name')?.touched && form.get('first_name')?.invalid"
        aria-describedby="firstnameValidation" />

      <input type="text" placeholder="Apellido *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3 form-control rounded-0" formControlName="last_name"
        [class.is-invalid]="form.get('last_name')?.touched && form.get('last_name')?.invalid"
        aria-describedby="last_nameValidation" />

      <input type="text" placeholder="Mail *" [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3  form-control rounded-0" formControlName="email"
        [class.is-invalid]="form.get('email')?.touched && form.get('email')?.invalid" aria-describedby="nameValidation" />
      <select [style.border]="'1.5px solid lightgray'" [style.font-size.px]="22"
        class="w-100 mt-3 pt-3 pb-1 px-3  form-control" formControlName="is_active"
        [class.is-invalid]="form.get('is_active')?.touched && form.get('is_active')?.invalid">
        <option value="true" selected>Activo</option>
        <option value="false">Inactivo</option>
      </select>

    </div>
    <div class="modal-footer" [ngClass]="{'d-flex justify-content-between': !nuevo}">
      <button (click)="restart()" type="button" class="btn btn-primary text-uppercase d-flex align-items-center" *ngIf="!nuevo"> <svg class="bi" width="20"
          height="18" fill="currentColor">
          <use xlink:href="assets/img/bootstrap-icons.svg#reply-fill" />
        </svg>
        <span class="align-middle"> Restaurar contraseña </span>
      </button>
      <button type="submit" class="btn btn-success text-uppercase">{{name_button}}</button>
    </div>
  </form>
</ng-template>
