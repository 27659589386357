import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigService } from '../shared/utils/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from './toast.service';
import { catchError, firstValueFrom, throwError, timeout } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  http: ConfigService;

  constructor(
    private readonly client: HttpClient,
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly toastService: ToastService,
  ) {
    this.http = new ConfigService(client);

  }

  get currentToken(): string | null {
    let user = localStorage.getItem('currentUser');
    return user;
  }

  set currentToken(sap) {
    localStorage.setItem('currentUser', sap ?? '');
  }

  get currentUser(): Object {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : {};  // Si 'user' es null
  }

  set currentUser(User: Object) {
    const userStr = JSON.stringify(User);
    localStorage.setItem('user', userStr);
  }

  login(username: string, password: string, isPage: boolean = true):any {
    const data = {
      username: username,
      password: password
    }
    this.spinner.show();
    this.http.post('auth/login/', data).pipe(
      timeout(5000), // Timeout de 5 segundos
      catchError((error) => {
        if (error.name === 'TimeoutError') {
          // Si se excede el tiempo de espera, se muestra un mensaje
          this.toastService.show('error', 'La solicitud de login ha tardado demasiado. Intente nuevamente.', {
            classname: 'bg-danger text-light toast-top-right',
            delay: 4000
          });
        } else {
          // Otro tipo de error, como credenciales incorrectas
          this.toastService.show('error', 'Su usuario y contraseña no son correctos', {
            classname: 'bg-danger text-light toast-top-right',
            delay: 4000
          });
        }
        this.spinner.hide();
        return throwError(() => error);
      })
    ).subscribe({
      next: (e: any) => {
        this.currentToken = e.code;
        this.currentUser = e;
        this.spinner.hide();
        if (isPage) {
          this.router.navigateByUrl('/admin/plants');
        } else {
          this.router.navigateByUrl('/');
        }
      }
    });
  }


  async check(): Promise<boolean> {
    const currentToken = this.currentToken;
    this.spinner.show();
    try {
      await firstValueFrom(this.http.post('auth/check/', { code: currentToken }));
      this.spinner.hide();
      return true;
    } catch (error) {
      this.spinner.hide();
      await this.router.navigateByUrl('/admin/login');
      return false;
    }
  }

  restar_psw(data: any) {
    return this.http.get<any>(`user/${data.id}/psw/`);
  }
  update_psw(id:number, data: any) {
    return this.http.put<any>(`user/${id}/psw/update/`, data);
  }
  put_user(data: any) {
    return this.http.put<any>(`user/${data.id}/`, data);
  }
  post_user(data: any) {
    return this.http.post<any>('user/', data);
  }
  create_update(form: any) {
    if (form.id) {
      return this.put_user(form);
    }
    return this.post_user(form);
  }
}
