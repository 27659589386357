<div class="px-5">
    <h1>Disponibilidad</h1>
    <div class="float-end p-5 ">
        <div class="custom-file">
            <input type="file" class="custom-file-input" id="customFileLang" lang="es"
                [(ngModel)]="file"
                (change)="onChange($event)">
            <label class="custom-file-label" for="customFileLang" data-browse="Elegir Archivo">Importar SVC
                Disponibilidad</label>
        </div>

    </div>
    <app-admin-table #table [mapHeader]="mapHeader" [queryset]="data$"></app-admin-table>
</div>
