<div class="container">
    <div class="bg-container-b container-fluid rounded-lg pt-5 pb-4">
        <div class="container text-center" *ngIf="dispatch | async as dispatch; else loading">
            <h1 class="display-4 text-uppercase">Detalle del pedido</h1>

            <h2 class="pt-3 color-primary text-uppercase">Código de orden</h2>
            <h3 class="pb-3 text-uppercase">{{ dispatch.order_buy }}</h3>

            <h2 class="pt-3 color-primary text-uppercase">Producto</h2>
            <h3 class="pb-3 text-uppercase">{{ dispatch.product }}</h3>

            <h2 class="pt-3 color-primary text-uppercase">Cantidad</h2>
            <h3 class="pb-3 ">{{ dispatch.request_amount }} m³
                <img class="ms-1" src="assets/img/truck.svg" alt="location" [height]="25" [width]="30">
            </h3>

            <h2 class="pt-3 color-primary text-uppercase">Precio Total</h2>
            <h1 class="pb-3 color-secondary text-uppercase">{{ dispatch.total_price | currency:'$ ':'symbol':'.0':'es' }}
            </h1>

            <h2 class="pt-3 color-primary text-uppercase">Estado</h2>
            <h3 class="pb-3 text-uppercase">{{ dispatch.state }}</h3>

            <h2 class="pt-3 color-primary text-uppercase">Dirección de entrega</h2>
            <h3 class="pb-3 text-uppercase">{{ dispatch.address_dispatch }}</h3>

            <h2 class="pt-3 color-primary text-uppercase">Fecha de entrega</h2>
            <h3 class="pb-3 text-uppercase">{{ dispatch.schedule }}</h3>
        </div>
        <div class="container text-center my-auto pt-5">
            <a routerLink="/" class="btn btn-secondary p-2 px-5 text-uppercase" [style.font-size.px]="20">
                Finalizar
            </a>
        </div>
    </div>
    <ng-template #loading>
    </ng-template>
</div>
