import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';
import { Region } from 'src/app/models/region';
import { REGIONES_COMUNA } from 'src/app/shared/utils/regiones';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';


@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.css']
})
export class RegionComponent implements OnInit {

  data$!: string;
  mapHeader = {
    id: { display: '#', data: null },
    name: { display: 'Región', data: null },
    code: { display: 'Código', data: null },
    actions: {
      display: 'Acciones',
      sort: false,
      data: () => {
        return `<button class="btn btn-link btn-edit">
                    <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="assets/img/bootstrap-icons.svg#pencil-square" />
                    </svg>
                </button>
              `;
      },
      edit: (data: any) => { this.edit(data); },
    },
  };
  regionesComunas = REGIONES_COMUNA;

  @ViewChild('table') table!: AdminTableComponent<Region>;
  @ViewChild('content', { static: true }) content!: ElementRef;

  form!: FormGroup;
  constructor(
    private readonly fb: FormBuilder,
    private readonly modalService: NgbModal,
    private readonly spinner: NgxSpinnerService,
    private readonly toastService: ToastService,
    private readonly storeService: StoreService,
    public responsiveService: ResponsiveLayoutService) {
  }

  ngOnInit(): void {
    this.data$ = 'region/';
    this.initForm({});
  }

  initForm(el: any) {
    this.form = this.fb.group({
      id: [el?.id],
      code: [el?.code, [Validators.required]],
      name: [el?.name, [Validators.required]],
    });
  }

  edit(ele: any) {
    this.form.reset(ele);

    this.modalService.open(this.content, { centered: true });
  }

  save() {
    if (this.form.valid) {
      this.spinner.show();
      this.storeService.put_regions(this.form.value).subscribe({
        next: () => {
          this.toastService.show('éxito', 'Se ha actualizado correctamente los datos', { classname: 'bg-success text-light toast-top-right', delay: 3000 });
          this.modalService.dismissAll();
          this.spinner.hide();
          this.table.reload();
        },
        error: () => {
          this.toastService.show('error', 'Por favor revise los datos', { classname: 'bg-danger text-light toast-top-right', delay: 4000 });
          this.spinner.hide();
        }
      });
    }
  }
}
