<div class="col pb-4" [class]="responsiveService.styleResponsive('px-2', 'px-5') | async">
  <div class="bg-light d-flex" [ngClass]="{'pb-2': !step}"
  [class]="(responsiveService.styleResponsive('px-2 pt-2', 'ps-5 pt-5') | async) ?? ''">
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="guardar()">
      <div [class]="responsiveService.styleResponsive('', 'row row-cols-12 col-12') | async">

        <ng-template ngFor let-category [ngForOf]="categories | async " let-i="index">
          <div #item_html class="px-0" *ngIf="general == null || category.id == general"
            [ngClass]="{'col-12 col-xl-7': !step, 'col-12 col-xl-6 mb-5': step}" @myInsertRemoveTrigger [@top-left]="{
                        value: category.id == general ? 'open' : 'closed',
                        params: {
                                offSetY: item_html.offsetTop * (i/i),
                                offSetX: item_html.offsetLeft
                            }
                        }" (@top-left.done)="showVolumen($event)">
            <div class="card border-0 bg-transparent" [style.min-height]="'330px'">
              <div class="row no-gutters">
                <div class="col clearfix" [class]="responsiveService.styleResponsive('d-none', '') | async">

                  <img [src]="url_img(category.id)" class="figure-img img-fluid" alt="" [style.height]="'100%'"
                    [style.width]="'100%'" style="box-shadow: 4px 5px #0000003d;">
                </div>
                <div class="col">
                  <div class="card-body d-flex flex-column py-0" [style.height]="'100%'">
                    <div class="">
                      <h3>{{category.name}}</h3>
                      <div class="my-auto">
                        <div class="py-auto form-check pt-1 mt-1 mb-4 ps-0 d-flex align-items-center"
                          *ngFor="let detail of category.sub_category">

                          <input type="radio" id="{{ detail.id }}" class="form-check-input" formControlName="detail"
                            [class.is-invalid]="form.get('detail')?.touched && form.get('detail')?.invalid"
                            value="{{ detail.id }}" [attr.disabled]="general == null  ? '' : null">
                          <label [ngClass]="{'form-check-label': general != null}"
                            for="{{ detail.id }}">
                          </label>
                          <span [ngClass]="{'ps-3': general != null}" for="{{ detail.id }}">{{ detail.name }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="mt-auto" *ngIf="general == null">
                      <button class="btn btn-secondary float-left  p-2 px-4" [style.font-size.px]="15"
                        (click)="fowardOption(category.id);">CONTINUAR</button>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-4 clearfix" *ngIf="general != null && detail != ''"
                  [class]="responsiveService.styleResponsive('px-0', 'px-3') | async">
                  <h4>
                    Especificiaciones:
                  </h4>
                  <div [class]="responsiveService.styleResponsive('col', 'row') | async">

                    <div class="col form-group pt-2 pb-0 mb-0">
                      <label for="waterproof" class="w-100 text-muted">Impermeabilizante</label>
                      <select class="form-control" formControlName="waterproof"
                        [class.is-invalid]="form.get('waterproof')?.touched && form.get('waterproof')?.invalid">
                        <option value="" selected disabled>Impermeabilizante</option>
                        <option value="0">No</option>
                        <option value="1">Sí</option>
                      </select>
                    </div>
                    <div class="col form-group pt-2 pb-0 mb-0" *ngIf="waterproof != ''">
                      <label for="product" class="w-100 text-muted">Resistencia</label>
                      <select class="form-control" formControlName="product"
                        [class.is-invalid]="form.get('product')?.touched && form.get('product')?.invalid">
                        <option value="" selected disabled>Resistencia</option>
                        <option *ngFor="let p of products" value="{{objectToString(p)}}">
                          {{p.age_name}}</option>
                      </select>
                    </div>
                    <div class="col text-center d-flex" *ngIf="product">
                      <label for="product" [style.width]="'100%'" ngbTooltip=" {{ product?.description }}"
                        [placement]="(responsiveService.styleResponsive('bottom', 'right') | async) ?? ''"
                        class="bg-primary text-white  pt-3 pb-2 rounded-pill justify-content-center mt-auto"
                        [class]="responsiveService.styleResponsive('px-0', 'px-3') | async">
                        {{ product?.name_comercial }}

                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="volumenCount" @enter-left
          [class]="responsiveService.styleResponsive('', 'row col-12 mt-5 mt-xl-0 col-xl-5') | async">
          <div class="m-0 p-0">
            <h3>Cubique su pedido aqui:</h3>
            <div class="d-flex pt-0 pb-1 align-items-end col-12">
              <div class="col-3 p-0">
                <label for="height" class="m-0 text-muted">Alto (m)</label>
                <p-inputNumber [(ngModel)]="height" mode="decimal" locale="es-CL" [minFractionDigits]="1"  [maxFractionDigits]="2"
                  [inputStyleClass]="'form-control'" [min]="0" [showButtons]="true" [step]="'.5'"
                  [incrementButtonClass]="'steeper-cubic'" [decrementButtonClass]="'steeper-cubic'"
                  [ngModelOptions]="{standalone: true}"></p-inputNumber>
              </div>
              <div class="col-3 ms-auto p-0">
                <label for="weight" class="m-0 text-muted">Ancho (m)</label>
                <p-inputNumber [(ngModel)]="weight" mode="decimal" locale="es-CL" [minFractionDigits]="1"  [maxFractionDigits]="2"
                  [showButtons]="true" [step]="'.5'" [incrementButtonClass]="'steeper-cubic'"
                  [decrementButtonClass]="'steeper-cubic'" [inputStyleClass]="'form-control'" [min]="0"
                  [ngModelOptions]="{standalone: true}"></p-inputNumber>
              </div>
              <div class="col-3 ms-auto p-0">
                <label for="depth" class="m-0 text-muted">Profundidad (m)</label>
                <p-inputNumber [(ngModel)]="depth" mode="decimal" locale="es-CL" [minFractionDigits]="1"  [maxFractionDigits]="2"
                  [showButtons]="true" [step]="'.5'" [incrementButtonClass]="'steeper-cubic'"
                  [decrementButtonClass]="'steeper-cubic'" [inputStyleClass]="'form-control'" [min]="0"
                  [ngModelOptions]="{standalone: true}"></p-inputNumber>
              </div>
              <button class="ms-auto col-2 btn btn-info h-50" type="button"
                (click)="calculateVolume()">Calcular</button>
            </div>

            <div class="d-flex pt-0 ">
              <div class="col-12">

                <div class="col-6 p-0">
                  <label for="m3" class="w-100 text-muted">Volumen</label>
                  <p-inputNumber [(ngModel)]="m3" mode="decimal" locale="es-CL" [minFractionDigits]="1" [maxFractionDigits]="1"
                    styleClass="w-100" [inputStyleClass]="'form-control  w-100'" [min]="2" [showButtons]="true"
                    [step]="'.5'" [incrementButtonClass]="'steeper-cubic'" [decrementButtonClass]="'steeper-cubic'"
                    [ngModelOptions]="{standalone: true}" (ngModelChange)="m3Change()"></p-inputNumber>
                </div>
                <div class="col-12 mt-3">
                  <img class="me-3" *ngFor="let item of countTruck(m3)" src="assets/img/truck.svg" alt="location"
                    [height]="50" [width]="50">
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-row-reverse">
            <div class="mt-auto">
              <button class="btn btn-cancel float-left py-2 ms-3" [style.font-size.px]="18"
                [class]="responsiveService.styleResponsive('', 'px-5') | async"
                (click)="backOption();">CANCELAR</button>
              <button class="btn btn-secondary float-left py-2 " [style.font-size.px]="18"
                [class]="responsiveService.styleResponsive('', 'px-5') | async">
                CONTINUAR</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
