import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';
import { ComunaPlant } from 'src/app/models/comuna-plant';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  @ViewChild('table') table!: AdminTableComponent<ComunaPlant>;
  @ViewChild('content', { static: true }) content!: ElementRef;
  form!: FormGroup;
  name_button!: string;
  data$!: string;
  nuevo = true;
  mapHeader = {
    email: { display: 'Correo', data: null },
    activa: { display: 'Estado', data: (data: any) => data.is_active ? 'Activa' : 'Inactiva' },
    actions: {
      display: 'Acciones',
      sort: false,
      data: (data: any) => {
        return `<button class="btn btn-link btn-edit">
                    <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="assets/img/bootstrap-icons.svg#pencil-square" />
                    </svg>
                </button>`;
      },
      edit: (data: any) => { this.edit(data); }
    },
  };

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly modalService: NgbModal,
    private readonly fb: FormBuilder,
    private readonly spinner: NgxSpinnerService,
    private readonly toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.data$ = 'user/';
    this.initForm({});
  }

  initForm(el: any) {
    this.form = this.fb.group({
      id: [],
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      is_active: ['', [Validators.required]],
    });
  }

  edit(ele: any) {
    this.name_button = 'Crear'
    this.nuevo = true;
    if (ele?.email) {
      this.nuevo = false;
      this.name_button = 'Actualizar'
    }

    this.form.reset(ele);
    this.form.get('id')?.setValue(ele.id);
    this.form.get('email')?.setValue(ele.email ?? '');
    this.form.get('first_name')?.setValue(ele.first_name ?? '');
    this.form.get('last_name')?.setValue(ele.last_name ?? '');
    this.form.get('is_active')?.setValue(ele.is_active ?? true);
    this.modalService.open(this.content, { centered: true });
  }
  restart() {
    this.authenticationService.restar_psw(this.form.value).subscribe({
      next: () => {
        this.update_create()
      },
      error: (e) => {
        this.error_http(e);
      }
    });
  }
  save() {
    if (this.form.valid) {
      this.spinner.show();
      this.authenticationService.create_update(this.form.value).subscribe({
        next: () => {
          this.update_create()
        },
        error: (e) => {
          this.error_http(e);
        }
      });
    }
  }



  error_http(e: any) {
    let mensaje = 'Algo a salido mal, por favor intente más tarde.'
    if (e && e.status == 409) {
      mensaje = e?.error.message;
    }
    this.toastService.show('', `${mensaje}`, { classname: 'bg-danger text-light this.toastService', delay: 4000 });
    this.spinner.hide();
  }

  update_create() {
    this.toastService.show('éxito', 'Se ha actualizado correctamente los datos', { classname: 'bg-success text-light toast-top-right', delay: 3000 });
    this.modalService.dismissAll();
    this.spinner.hide();
    this.table.reload();
  }

  objectToString(objeto: Object) {
    return JSON.stringify(objeto)
  }
}
