import { Component, OnInit } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
  data$!: string;
  mapHeader = {
    id: { display: '#', data: null, visible:false },
    name: { display: 'Nombre', data: null },
    email: { display: 'Mail', data: null },
    phone: { display: 'Teléfono', data: null },
    region: { display: 'Región', data: null },
    comuna: { display: 'Comuna', data: null },
    question: { display: 'Consulta', data: null },
    created_at: { display: 'Fecha', data: null },
  };

  constructor(private readonly storeService: StoreService,
    public responsiveService: ResponsiveLayoutService, private readonly excelService: ExcelService) {
  }

  ngOnInit(): void {
    this.data$ = 'help/';
  }
}

