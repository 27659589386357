import { Injectable } from '@angular/core';
import { Order, Location } from '../models/order';
import { StoreStep, MapStep, Address, UserDelivery } from '../models/store-step';
import { Schedule } from '../models/schedule';
import { Product } from '../models/product';
import { User } from '../models/user';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private order: Order;
  private storeStep: StoreStep;


  constructor() {
    this.order = this.currentOrder ?? new Order;
    this.storeStep = this.currentForm ?? new StoreStep;
  }

  setUserData(user: User) {
    this.order.user = user;
    this.currentOrder = this.order;
  }

  public setLocation(loc: Location, plant?: number) {
    this.order.map = loc;
    this.order.plant = plant;
    this.currentOrder = this.order;
  }

  public setUser(user: User) {
    this.order.user = user;
    this.currentOrder = this.order;
  }


  setSchedule(schedule: Schedule) {
    this.order.schedule = schedule;
    this.currentOrder = this.order;
  }

  setProduct(product: Product) {
    this.order.product = product;
    this.currentOrder = this.order;
  }

  reset() {
    localStorage.clear();
    this.order = new Order;
    this.storeStep = new StoreStep;
  }

  public get currentOrder() {
    const storedData = localStorage.getItem('currentOrder');
    this.order = storedData ? JSON.parse(storedData) : null;
    return this.order;
  }

  public set currentOrder(_order: Order) {
    localStorage.setItem('currentOrder', JSON.stringify(_order));
  }


  setUserOrdenData(order: any, usuario: any) {
    this.order.user = usuario;
    this.order.id = order.id;
    this.order.order_buy = order.order_buy;
    this.currentOrder = this.order;
  }


  setfineOrdenData(fine: number) {
    this.order.fine = fine;
    this.currentOrder = this.order;
  }


  /*
   * Funcion para obener los valores almacenados de tipo obj
   * @param item String nombre de la variable a guardar
   *             address (obj): Direccion guardada desde el map
   * @returns si existe error
   */

  getStorageDataObject(item: string): any {
    const data = this.getStorageData(item);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  getStorageData(item: string): any {
    if (localStorage.getItem(item)) {
      return localStorage.getItem(item);
    }
    return null;
  }

  /*
   * Funcion para almacenar los valores
   * @param item String nombre de la variable a guardar
   *             userDelivery: Usuario para el pedido guardada desde el map
   * @param value variable a guardar, puede ser Obj o String
   * @returns si existe error
   */
  setStorageData(item: string, value: any) {
    try {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      localStorage.setItem(item, value);
      return null;
    } catch (error) {
      return throwError(() => new Error('El valor no es valido para almacenar'));
    }
  }

  // Seccion estado del formulario
  public get currentForm() {
    const storedData = localStorage.getItem('currentOrder');
    return storedData ? JSON.parse(storedData) : null;
  }
  public set currentForm(value: StoreStep) {
    localStorage.setItem('currentForm', JSON.stringify(value));
  }
  public getAddress() {
    let map = this.storeStep?.mapStep;
    return map?.address ?? null;
  }
  public getUser() {
    let map = this.storeStep?.mapStep;
    return map?.userDelivery ?? null;
  }
  public setMap(adrres: Address, userDelivery: UserDelivery) {
    if (!this.storeStep.mapStep) {
      this.storeStep.mapStep = new MapStep();
    }
    this.storeStep.mapStep.address = adrres;
    this.storeStep.mapStep.userDelivery = userDelivery;
    this.currentForm = this.storeStep;
  }

  public setProductoGeneral(general: number) {
    if (this.storeStep.product) {
      this.storeStep.product.general = general;
    }else {
      console.error("Error: `product` no está definido.");
    }
    this.currentForm = this.storeStep;
  }

}
