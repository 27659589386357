import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StoreService } from 'src/app/services/store.service';
import { ResponsiveLayoutService } from 'src/app/services/responsive-layout.service';
import { ExcelService } from 'src/app/services/excel.service';
import { Setting } from 'src/app/models/setting';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AdminTableComponent } from 'src/app/shared/admin-table/admin-table.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  data$!: string;
  mapHeader = {
    id: { display: '#', data: null },
    name: { display: 'Nombre', data: null },
    value: { display: 'Valor', data: null },
    actions: {
      display: 'Acciones',
      sort: false, data: () => {
        return `<button class="btn btn-link btn-edit">
                  <svg class="bi" width="20" height="20" fill="currentColor">
                      <use xlink:href="assets/img/bootstrap-icons.svg#pencil-square" />
                  </svg>
              </button>`;
      },
      edit: (data: any) => { this.edit(data) }
    },
  };
  @ViewChild('table') table!: AdminTableComponent<Setting>;
  @ViewChild("content", { static: true }) content!: ElementRef;

  form!: FormGroup;
  constructor(
    private readonly fb: FormBuilder,
    private readonly modalService: NgbModal,
    private readonly spinner: NgxSpinnerService,
    private readonly toastService: ToastService,
    private readonly excelService: ExcelService,
    private readonly storeService: StoreService,
    public responsiveService: ResponsiveLayoutService) {
  }

  ngOnInit(): void {
    this.data$ = 'settings/';
    this.initForm({});
  }

  initForm(el: any) {
    this.form = this.fb.group({
      id: [el?.id, [Validators.required]],
      name: [el?.name, [Validators.required]],
      value: [el?.value, [Validators.required]],
    });
  }

  edit(ele: any) {
    this.form.reset(ele);

    this.modalService.open(this.content, { centered: true });
  }

  save() {
    if (this.form.valid) {
      this.spinner.show()
      this.storeService.put_settings(this.form.value).subscribe({
        next: () => {
          this.toastService.show('éxito', 'Se ha actualizado correctamente los datos', { classname: 'bg-success text-light toast-top-right', delay: 3000 });
          this.modalService.dismissAll();
          this.spinner.hide()
          this.table.reload();
        },
        error: () => {
          this.toastService.show('error', 'Por favor revise los datos', { classname: 'bg-danger text-light toast-top-right', delay: 4000 });
          this.spinner.hide();
        }
      });
    }
  }
}

