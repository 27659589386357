<div class="py-5">
    <div class="d-flex">
        <div class="col-6" *ngIf="exportFile">
            <div class="float-end">
                <button class="btn btn-md btn-info" (click)="exportData()">Exportar Excel</button>
            </div>
        </div>
    </div>
    <table #table datatable [dtOptions]="dtOptions" class="row-border hover" aria-describedby="table">
        <thead>
            <tr>
                <th scope="col" sortable="{{item.key}}" (sort)="onSort($event)"
                    *ngFor="let item of mapHeader | keyvalue : compare">
                    {{ getDisplay(item.value) }}
                </th>
            </tr>
        </thead>
        <tbody>
        </tbody>
    </table>
</div>
