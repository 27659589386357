<div class="px-5">
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="login()" class="px-4 py-3 text-center">
        <div class="d-flex justify-content-center">
            <div class="text-center" [ngClass]="{'col-6':isPage, 'col-12':!isPage}">
                <div class="" >
                    <img src="assets/img/logo.svg" [ngStyle]="{'width.px': ancho}" alt="" loading="lazy">
                </div>

                <div class="form-group mt-3">
                    <input type="text" placeholder="Mail *" [style.border]="'1.5px solid lightgray'"
                        [style.font-size.px]="22" class="form-control"
                        formControlName="username"
                        [class.is-invalid]="form.get('username')?.touched && form.get('username')?.invalid"
                        aria-describedby="usernameValidation" />
                </div>

                <div class="form-group">
                    <input type="password" placeholder="Contraseña *" [style.border]="'1.5px solid lightgray'"
                        [style.font-size.px]="22" class="mt-3 pt-3 pb-1 px-3 form-control rounded-0"
                        formControlName="password" [class.is-invalid]="form.get('password')?.touched && form.get('password')?.invalid"
                        aria-describedby="passwordValidation" />
                </div>
                <div class="pt-3">
                    <button type="submit" class="btn btn-primary "
                    [ngClass]="{ 'text-uppercase': isPage, 'bg-secondary': !isPage}"
                    [ngStyle]="{'font-size.px': isPage ? 22: null}"
                    >Entrar</button>
                </div>
            </div>
        </div>
    </form>
</div>
